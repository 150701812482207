import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import { addDays, differenceInDays, format } from "date-fns";
import DateSelector from "../components/typedate";
import { handleDateChange } from "../utils/params";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import pushEventsToDataLayer from "../utils/gtm";
const DateRangePicker = ({
  dateStart,
  dateEnd,
  setTypeSelected,
  isComparing,
  dateStartCompare,
  dateEndCompare,
  typeSelected,
  setTypeSelectedCompare,
  typeSelectedCompare,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleCompareToggle = () => {
    if (!isComparing_) {
      if (dateStartCompare && dateEndCompare) {
        setValuesCompare([dateStartCompare, dateEndCompare]);
      } else {
        setTypeSelectedCompare("previousPeriod");
        calculateDateCompare(
          formatDate(values[0]),
          formatDate(values[1]),
          "previousPeriod"
        );
      }
    }
    setIsComparing_(!isComparing_);
  };

  const calculateDateCompare = (dateStart, dateEnd, type) => {
    const difference = differenceInDays(dateEnd, dateStart);
    let newDateStart, newDateEnd;
    if (!dateEnd || !dateStart) {
      return;
    }
    switch (type) {
      case "previousPeriod":
        newDateStart = format(
          addDays(dateStart, -(difference + 1)),
          "yyyy-MM-dd"
        );
        newDateEnd = format(addDays(dateStart, -1), "yyyy-MM-dd");
        setValuesCompare([newDateStart, newDateEnd]);
        break;
      case "previousYear":
        newDateStart = format(addDays(dateStart, -366), "yyyy-MM-dd");
        newDateEnd = format(addDays(dateEnd, -366), "yyyy-MM-dd");
        setValuesCompare([newDateStart, newDateEnd]);
        break;
      default:
        setValuesCompare([dateStart, dateEnd]);
        break;
    }
  };

  const [values, setValues] = useState([dateStart, dateEnd]);

  const fixMonth = (month) => {
    if (month < 10) {
      return "0" + month;
    }
    return month;
  };

  const formatDate = (date) => {
    if (typeof date === "string") {
      return date;
    } else if (date && date.year && date.month && date.day) {
      return (
        date.year + "-" + fixMonth(date.month.number) + "-" + fixMonth(date.day)
      );
    } else {
      return null;
    }
  };

  const handleApply = () => {
    // check values[0] is a string or object
    pushEventsToDataLayer({
      'event': "timeframe_click",
      "data-click-text": "apply",
    });
    const dateStart_ = formatDate(values[0]);
    const dateEnd_ = formatDate(values[1]);
    if (isComparing_ && valuesCompare && valuesCompare.length > 0) {
      const dateStartCompare_ = formatDate(valuesCompare[0]);
      const dateEndCompare_ = formatDate(valuesCompare[1]);
      if (dateStart_ && dateEnd_ && dateStartCompare_ && dateEndCompare_) {
        handleDateChange(
          ["dateStart", "dateEnd", "dateStartCompare", "dateEndCompare"],
          [dateStart_, dateEnd_, dateStartCompare_, dateEndCompare_],
          false,
          navigate,
          location,
          queryString
        );
        return;
      } else {
        alert("Please select date range");
        return;
      }
    }
    if (dateEnd_ && dateStart_) {
      handleDateChange(
        ["dateStart", "dateEnd"],
        [dateStart_, dateEnd_],
        true,
        navigate,
        location,
        queryString
      );
      return;
    } else {
      alert("Please select date range");
      return;
    }
  };

  const [isComparing_, setIsComparing_] = useState(isComparing);

  const [valuesCompare, setValuesCompare] = useState([
    dateStartCompare,
    dateEndCompare,
  ]);

  const changeValues = (e) => {
    setValues(e);
    const startDate = formatDate(e[0]);
    const endDate = formatDate(e[1]);
    if (isComparing_) {
      calculateDateCompare(startDate, endDate, typeSelectedCompare);
    }
  };
  const changeCompare = (e) => {
    setValuesCompare(e);
    setTypeSelectedCompare("custom");
    // if (isComparing_) {
    //   calculateDateCompare(startDate, endDate, typeSelectedCompare);
    // }
  };

  return (
    <div className="flex flex-col p-2 min-w-[380px] " id="compare-data">
      <div className="flex gap-2 justify-between">
        <DatePicker
          value={values}
          onChange={(e) => {
            setValues(e);
            setTypeSelected("custom");
          }}
          range
          maxDate={new Date()}
          // format="YYYY MM DD"
          numberOfMonths={2}
          calendarPosition="bottom-right"
          style={{
            width: "210px",
          }}
          onChangeRaw={(e) => {
            e.preventDefault();
          }}
        />
        <DateSelector
          typeSelected={typeSelected}
          setTypeSelected={setTypeSelected}
          setValues={changeValues}
          date={[
            { value: "custom", label: "Custom" },
            { value: "today", label: "Today" },
            { value: "yesterday", label: "Yesterday" },
            { value: "lastWeek", label: "Last week" },
            { value: "lastMonth", label: "Last month" },
            { value: "last7Days", label: "Last 7 days" },
            { value: "last30Days", label: "Last 30 days" },
          ]}
        />
      </div>
      <div>
        <div className="flex gap-2">
          <input
            type="checkbox"
            id="compare"
            checked={isComparing_}
            onChange={handleCompareToggle}
          />
          <label htmlFor="compare">Compare to:</label>
        </div>
        <div>
          {isComparing_ && (
            <div className="flex gap-2">
              <DatePicker
                value={valuesCompare}
                onChange={changeCompare}
                range
                maxDate={new Date()}
                numberOfMonths={2}
                calendarPosition="bottom-right"
                style={{
                  width: "210px",
                }}
              />
              <DateSelector
                typeSelected={typeSelectedCompare}
                setTypeSelected={setTypeSelectedCompare}
                setValues={(e) => {
                  setTypeSelectedCompare(typeof e === "string" ? e : "custom");
                  calculateDateCompare(
                    formatDate(values[0]),
                    formatDate(values[1]),
                    e
                  );
                }}
                date={[
                  { value: "previousPeriod", label: "Previous period" },
                  { value: "previousYear", label: "Previous year" },
                  { value: "custom", label: "Custom" },
                ]}
              />
            </div>
          )}
        </div>
      </div>
      <button
        onClick={handleApply}
        className="mt-1 w-fit text-gray-500 text-xs font-bold py-1 px-2 rounded border  hover:text-white hover:bg-gray-500 border-gray-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
      >
        Apply
      </button>
    </div>
  );
};

export default DateRangePicker;
