import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/loader';
import {
  projectsState,
  LogoutCache
} from "../utils/atom/projects";
import { useRecoilState } from "recoil";

const Home = ({ cookies, larghezzaPagina }) => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/");
  // }, [])
  const [projects, setProjects] = useRecoilState(projectsState);
  const [loading, setLoading] = useState(true);

  const [message, setMessage] = useState('');
  useEffect(() => {
    if (projects && projects?.pageCache?.page?.projects?.length === 0) {
      setMessage("You don't have any analytics project, if you think it's a mistake, contact the support")
      setLoading(false);
    }
    if (projects && projects.error) {
      setMessage("Insufficient permissions. Please log out and log in again with the necessary permissions.")
      setLoading(false);
    }
  }
    , [projects])

  const logout = async () => {
    const user_ = cookies.get("user");
    if (user_ && user_.id) {
      const response = await fetch(
        process.env.REACT_APP_URL_BACKEND + "users/" + user_.id,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${cookies.get("jwt")}`,
          },

          body: {},
        }
      );
      await response.json();
    }
    LogoutCache(setProjects, cookies);
    cookies.remove("user");
    cookies.remove("jwt");
    cookies.remove("color");
    cookies.remove("positions");
    cookies.remove("Wizard");
    cookies.remove("Wizard_welcome");
    navigate("/login");
  };

  return (
    <div className='h-screen bg-white flex justify-center items-center p-20' >
      {loading ?
        <Loader />
        :
        <div className='text-xl w-[450px] text-center'>
          {message}<br />
          <a href='mailto:info@yourvisualdata.com' className='text-blue-500 hover:underline'>
            info@yourvisualdata.com
          </a>
          <br />
          {message.startsWith("Insufficient permissions") &&
            <button onClick={logout} className='mt-4  rounded-md p-2
            bg-gray-200
            hover:bg-gray-300'>
              Logout
            </button>
          }
        </div>
      }
    </div >
  );
};

export default Home;
