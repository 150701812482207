import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ButtonSidebar from "../components/buttonSidebar";
import homeN from "../assets/homen.svg";
import channelN from "../assets/channelN.svg";
import landingPageN from "../assets/landingPageN.svg";
import imgprofilo from "../assets/imgprofilo.png";
import queryString from "query-string";
import { createParamsSearch } from "../utils/params";

function Sidebar({ sidebarOpen, setSidebarOpen, cookies }) {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { pathname } = location;
  let page = pathname.split("/")[1];
  const trigger = useRef(null);
  const sidebar = useRef(null);



  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current?.contains(target) ||
        trigger.current?.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });
  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const router = useParams();

  const [image, setImage] = useState("");
  useEffect(() => {
    const user_ = cookies.get("user");
    if (user_) {
      setImage(user_.picture);
    }
  }, [router]);

  const handleImageError = () => {
    setImage(imgprofilo);
  };


  

  return (
    <div
      className={
        sidebarOpen
          ? "w-[150px] z-20 fixed block pt-[60px] bg-white"
          : "w-[66px] z-20 fixed block pt-[60px] bg-white"
      }
    >
      <div
        style={{
          borderTopWidth: "0px",
          borderLeftWidth: "0px",
          borderBottomWidth: "0px",
        }}
        onMouseEnter={() => setSidebarOpen(true)}
        onMouseLeave={() => setSidebarOpen(false)}
        id="sidebar"
        ref={sidebar}
        className="border-r border-solid border-lightgray absolute z-20 left-auto top-0 translate-x-0 hover:transition-all transform h-screen overflow-x-hidden overflow-y-auto border-solid  no-scrollbar w-[66px] transition  ease-in-out transition-transform hover:w-[150px] flex-shrink-0 bg-white p-[10px] relative"
      >
        <div className="relative">
          <ul className="mt-3" id="sidebar">
            <ButtonSidebar
              page={page}
              sidebarOpen={sidebarOpen}
              name={"overview"}
              path={
                "visitors-overview/" + router.properties + "/" + router.project + "?" + createParamsSearch(queryParams)
              }
              imgNera={landingPageN}
              setSidebarOpen={setSidebarOpen}
              pathname={pathname}
              selected={"visitors-overview"}
            />
            <ButtonSidebar
              page={page}
              sidebarOpen={sidebarOpen}
              name={"channel"}
              path={
                "acquisition-channels/" +
                router.properties +
                "/" +
                router.project + "?" + createParamsSearch(queryParams)
              }
              imgNera={homeN}
              setSidebarOpen={setSidebarOpen}
              pathname={pathname}
              selected={"acquisition-channels"}
            />
            <ButtonSidebar
              page={page}
              sidebarOpen={sidebarOpen}
              name={"landing"}
              path={
                "content-landing-pages/" +
                router.properties +
                "/" +
                router.project + "?" + createParamsSearch(queryParams)
              }
              imgNera={channelN}
              setSidebarOpen={setSidebarOpen}
              pathname={pathname}
              selected={"content-landing-pages"}
            />
          </ul>
        </div>
        <div
          className={
            sidebarOpen
              ? "absolute bottom-28 w-[84%]"
              : "absolute bottom-28 w-min"
          }
        >
          <div className="mx-center">
            <ul>
              <li
                className={`transition ease-in-out px-[13px] py-[13px] w-full rounded-[12px] mt-[12px] cursor-pointer`}
              >
                <span
                  className={
                    sidebarOpen
                      ? "w-full flex items-center "
                      : "w-[20px] h-[20px]  flex items-center"
                  }
                >
                  {image && (
                    <img
                      src={image || imgprofilo}
                      alt="profile"
                      className="w-[20px] h-[20px] rounded-full"
                      onError={handleImageError}
                    />
                  )}
                  <span className="ml-[5px] w-full flex items-center justify-between">
                    {sidebarOpen && <h4>Profile</h4>}
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
