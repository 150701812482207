import { useEffect } from 'react';
import PropTypes from 'prop-types';

const Start = ({ startTour, cookies }) => {
  useEffect(() => {
    if (startTour && startTour.start) { // Ensure startTour is defined and has a start function
      const interval = setInterval(() => {
        if(cookies.get('Wizard') === true) {
          if (!cookies.get('Wizard_welcome')) {
            startTour.start(); // Call the start function from the tour context
            cookies.set('Wizard_welcome', 'true');
          }
        }
      }, 1000);

      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [startTour]);

  return null;
};

Start.propTypes = {
  startTour: PropTypes.shape({
    start: PropTypes.func
  }),
};

export default Start;
