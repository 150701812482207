import React, {useEffect} from 'react';
import Logo from "../assets/YourVisualData/logo scritta nera.png";
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Privacy Policy | YourVisualData';
  }
    , []);
  return (
    <>
      <header
      className=" top-0 bg-white z-50 border-b remove-border-no-bottom border-lightgray fixed w-full border-solid"
      style={{
        borderTopWidth: "0px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
      }}
    >
      <div>
        <div className="flex items-center justify-between h-[60px] px-4 md:px-[40px]">
          <div>
          <div
              className="flex cursor-pointer  items-end gap-1 w-fit"
              onClick={() => {
                navigate("/");
              }}
            >
              <LazyLoadImage src={Logo} width="150" />
              <span className="text-[10px] whitespace-nowrap h-[17px]">
                | Powered by Fortop
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
 
    <div className="max-w-4xl mx-auto p-6 mt-20">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        On this page we explain how we process your personal data in accordance with Regulation (EU) 2016/679 (GDPR) and Legislative Decree 196/2003 (Privacy Code).
      </p>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Data controller</h2>
        <p className="mb-2">Fortop s.r.l., Milan (MI), Corso Sempione, 10</p>
        <p className="mb-2">E-mail: info@yourvisualdata.com</p>
        <p className="mb-2">
          The Data Controller has appointed a Data Protection Officer (DPO) who you can contact by writing to dpo@fortop.it
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Data that is collected</h2>
        <p className="mb-2">
          The only data saved is Name, Surname, Email and Avatar and is used to keep your user logged in to the service the next time they log in.
        </p>
        <p className="mb-2">
          No Google Analytics data is saved or transited on the servers of Yourvisualdata.com; only the logged-in user can see their data to which they have access.
        </p>
        <p className="mb-2">
          The user's preferences such as the last view visited, the arrangement of the blocks and the selected colors will be saved in the user's browser (cookies) and will not transit or be saved on the servers of Yourvisualdata.com.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Applications</h2>
        <p className="mb-2">The areas used are the following:</p>
        <ul className="list-disc list-inside mb-2">
          <li>https://www.googleapis.com/aut/auth/userinfo.email - To allow the user to create their own profile on the dashboard.</li>
          <li>https://www.googleapis.com/auth/auth/userinfo.profile - To allow the user to create their own profile on the dashboard.</li>
          <li>https://www.googleapis.com/auth/analytics.readonly - To allow the user to view their data within the dashboard.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Site statistics and testing</h2>
        <p className="mb-2">
          Thanks to some services offered by third parties, we monitor and analyse traffic data and keep track of your behaviour on our site, in order to guarantee you the best possible user experience.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Google Tag Manager</h2>
        <p className="mb-2">
          Google Tag Manager is a tag management service provided by Google LLC. This is the privacy policy (<a href="https://www.google.com/intl/it/policies/privacy/" className="text-blue-500 underline">https://www.google.com/intl/it/policies/privacy/</a>) of Google Inc.
        </p>
        <p className="mb-2">
          The data is processed in the USA. Google Inc. adheres to the Privacy Shields, the agreement that regulates the transfer of data between the European Union and the USA.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Google Analytics</h2>
        <p className="mb-2">
          Google Analytics is a web analysis service provided by Google Inc. Google uses the Personal Data collected for the purpose of tracking and examining the use of this Website, compiling reports and sharing them with other services developed by Google.
        </p>
        <p className="mb-2">
          Google may use Personal Data to contextualise and personalise ads in its advertising network. This is the privacy policy (<a href="https://www.google.com/intl/it/policies/privacy/" className="text-blue-500 underline">https://www.google.com/intl/it/policies/privacy/</a>) of Google Inc.
        </p>
        <p className="mb-2">
          The data is processed in the USA. Google Inc. adheres to the Privacy Shields, the agreement regulating data transfer between the European Union and the USA. You can disable these cookies from here (<a href="https://tools.google.com/dlpage/gaoptout?hl=it" className="text-blue-500 underline">https://tools.google.com/dlpage/gaoptout?hl=it</a>)
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Interaction with social media and external platforms</h2>
        <p className="mb-2">
          Through this type of service you can interact with social networks or other external platforms, directly from the pages of this site. The data collected by our site depends on the privacy settings you have set on each social network or external platform.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Subjects accessing the data</h2>
        <p className="mb-2">
          In addition to us, in some cases, data may be accessed by categories of persons involved in the organisation of the site (administrative, commercial, marketing, legal personnel, system administrators) or external subjects (such as third-party technical service providers, hosting providers, IT companies, communication agencies) also appointed, if necessary, as Data Processors by us. You can request an updated list of External Processors.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Your rights</h2>
        <p className="mb-2">You can exercise certain rights in relation to the processing of your data. In particular, you have the right to:</p>
        <ul className="list-disc list-inside mb-2">
          <li>revoke consent at any time. You can withdraw your consent to the processing of your data that you have previously expressed.</li>
          <li>oppose the processing of your data. You can object to the processing of your data when it occurs on a legal basis other than consent. More details on the right to object are given in the section below.</li>
          <li>access your data. The User has the right to obtain information about the Data processed by the Owner, certain aspects of the processing, and to receive a copy of the processed Data.</li>
          <li>verify and request rectification. You can verify the correctness of your data and request its updating or correction.</li>
          <li>obtain limitation to processing. When certain conditions are met, you can request the limitation of the processing of your data. In this case, the Owner will not process the Data for any purpose other than its preservation.</li>
          <li>have their personal data removed or deleted. When certain conditions apply, you can request the erasure of your data by the Data Controller.</li>
          <li>have their data sent to themselves or transferred to another controller. Users have the right to receive their data in a structured format that is commonly used and readable by automatic devices, and where technically feasible, to have it transferred without hindrance to another controller. This instruction is applicable where the data is processed by automated means and processing is based on user consent, in respect of a contract to which the user is a party or contractual measures connected to this.</li>
          <li>lodge a complaint. Users may submit a complaint with the competent supervisory authority for data protection or take legal action.</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Details on the right to object</h2>
        <p className="mb-2">
          If your data is processed for direct marketing purposes, you can object to the processing at any time.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">How to exercise your rights</h2>
        <p className="mb-2">
          To exercise these rights, write to us at: info@yourvisualdata.com. We will respond as soon as possible and in any case no later than one month from the sending of your request.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Transfer of data abroad</h2>
        <p className="mb-2">
          Data may be transferred to Italy and/or abroad, and in particular also outside the European Union, by virtue of adequacy decisions of the Third Country or standard clauses relating to data protection adopted by the European Commission, in order to guarantee effective protection of the rights of data subjects. The Data Controller undertakes to limit such data transfer to Third Countries to what is necessary due to the technological solutions adopted.
        </p>
      </section>

      <section className="mb-6">
        <h2 className="text-xl font-semibold mb-2">COOKIES POLICY</h2>
        <p className="mb-2">
          This Website uses cookies. To learn more and to view the detailed information, the user can consult the information on cookies.
        </p>
      </section>
    </div>
    </>
  );
}

export default PrivacyPolicy;