import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns"; // to handle time scales
import { getISOWeek, format, addDays } from "date-fns";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

const AnalyticsChart = ({
  rows,
  position,
  buttonSelected,
  setPos,
  pos,
  color,
  dateStartCompare,
  dateEndCompare,
  isComparing,
  dateStart,
  dateEnd,
  secondPosition,
  media,
}) => {
  const [granularity, setGranularity] = useState(buttonSelected);
  // Sample data from the provided output

  const getLabelsAndData = (rows, granularity, position_) => {
    switch (granularity) {
      case "hourly":
        const hourlyData = rows
          .reduce((acc, row) => {
            const dateStr = row.dimensionValues[0]?.value || "";
            const hourStr = row.dimensionValues[1]?.value || "00";

            // Controlla se il formato di data e ora è corretto
            if (dateStr.length === 8 && hourStr.length <= 2) {
              const year = dateStr.substring(0, 4);
              const month = dateStr.substring(4, 6);
              const day = dateStr.substring(6, 8);
              const date = `${year}-${month}-${day}`;
              const hour = hourStr.padStart(2, "0");

              const dateTimeStr = `${date}T${hour}:00:00`;
              const value =
              parseFloat(row.metricValues[position_]?.value, 10) || 0;

              const existingIndex = acc.findIndex(
                (item) => item.dateTime === dateTimeStr
              );

              if (existingIndex >= 0) {
                acc[existingIndex].value += value;
              } else {
                acc.push({ dateTime: dateTimeStr, value });
              }
            } else {
              console.error("Invalid date or hour format:", dateStr, hourStr);
            }

            return acc;
          }, [])
          .map((item) => item.value);
        const labels = [
          ...new Set(
            rows.map((row) => {
              const dateStr = row.dimensionValues[0]?.value || "";
              const hourStr = row.dimensionValues[1]?.value || "00";

              if (dateStr.length === 8 && hourStr.length <= 2) {
                const year = dateStr.substring(0, 4);
                const month = dateStr.substring(4, 6);
                const day = dateStr.substring(6, 8);
                const hour = hourStr.padStart(2, "0");

                return `${year}-${month}-${day}T${hour}:00:00`;
              } else {
                console.error("Invalid date or hour format:", dateStr, hourStr);
                return null;
              }
            })
          ),
        ]
          .filter(Boolean)
          .sort();
        return {
          labels: labels,
          dataPoints: hourlyData,
        };
      case "daily":
        return {
          labels: [
            ...new Set(rows.map((row) => row.dimensionValues[0]?.value)),
          ].map(
            (dateStr) =>
              `${dateStr.substring(0, 4)}-${dateStr.substring(
                4,
                6
              )}-${dateStr.substring(6, 8)}`
          ),
          dataPoints: rows
            .reduce((acc, row) => {
              const dateStr = row.dimensionValues[0]?.value;
              const existingIndex = acc.findIndex(
                (item) => item.date === dateStr
              );
              const value = parseFloat(row.metricValues[position_]?.value, 10);
              if (existingIndex >= 0) {
                acc[existingIndex].value += value;
              } else {
                acc.push({ date: dateStr, value });
              }
              return acc;
            }, [])
            .map((item) => item.value),
        };
      case "monthly":
        return {
          labels: [
            ...new Set(
              rows.map((row) => row.dimensionValues[0]?.value.substring(0, 6))
            ),
          ].map(
            (dateStr) =>
              new Date(`${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}`)
          ),
          dataPoints: rows
            .reduce((acc, row) => {
              const monthStr = row.dimensionValues[0]?.value.substring(0, 6);
              const existingIndex = acc.findIndex(
                (item) => item.month === monthStr
              );
              const value = parseFloat(row.metricValues[position_]?.value, 10);
              if (existingIndex >= 0) {
                acc[existingIndex].value += value;
              } else {
                acc.push({ month: monthStr, value });
              }
              return acc;
            }, [])
            .map((item) => item.value),
        };
      case "weekly":
        return {
          labels: [
            ...new Set(
              rows.map((row) => {
                const dateStr = row.dimensionValues[0]?.value;
                const year = dateStr.substring(0, 4);
                const month = dateStr.substring(4, 6);
                const day = dateStr.substring(6, 8);
                const date = new Date(`${year}-${month}-${day}`);
                const week = getISOWeek(date);
                return `${year}-W${week}`;
              })
            ),
          ],
          dataPoints: rows
            .reduce((acc, row) => {
              const dateStr = row.dimensionValues[0]?.value;
              const year = dateStr.substring(0, 4);
              const month = dateStr.substring(4, 6);
              const day = dateStr.substring(6, 8);
              const date = new Date(`${year}-${month}-${day}`);
              const week = getISOWeek(date);
              const weekStr = `${year}-W${week}`;
              const existingIndex = acc.findIndex(
                (item) => item.week === weekStr
              );
              const value = parseFloat(row.metricValues[position_]?.value, 10);
              if (existingIndex >= 0) {
                acc[existingIndex].value += value;
              } else {
                acc.push({ week: weekStr, value });
              }
              return acc;
            }, [])
            .map((item) => item.value),
        };
      case "yearly":
        return {
          labels: [
            ...new Set(
              rows.map((row) => row.dimensionValues[0]?.value.substring(0, 4))
            ),
          ].map((dateStr) => new Date(dateStr)),
          dataPoints: rows
            .reduce((acc, row) => {
              const yearStr = row.dimensionValues[0]?.value.substring(0, 4);
              const existingIndex = acc.findIndex(
                (item) => item.year === yearStr
              );
              const value = parseFloat(row.metricValues[position_]?.value, 10);
              if (existingIndex >= 0) {
                acc[existingIndex].value += value;
              } else {
                acc.push({ year: yearStr, value });
              }
              return acc;
            }, [])
            .map((item) => item.value),
        };
      default:
        return {
          labels: [],
          dataPoints: [],
        };
    }
  };

  // const { labels, dataPoints } = getLabelsAndData(rows, granularity, position);

  const options = {
    elements: {
      point: {
        radius: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        scaleLineColor: "transparent",
        type: "time",
        time: {
          unit:
            granularity === "hourly"
              ? "day"
              : granularity === "daily"
              ? "day"
              : granularity === "weekly"
              ? "week"
              : granularity === "monthly"
              ? "month"
              : "year",
        },
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
        beginAtZero: true,
      },
      y: {
        scaleLineColor: "transparent",
        borderWidth: 0,
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
        beginAtZero: true,
        grid: {
          scaleLineColor: "transparent",
          display: false,
          drawBorder: false,
          borderColor: "transparent",
          borderWidth: 0,
          drawTicks: false,
        },
        padding: 1,
      },
    },
    ticks: {
      min: 0,
    },
    plugins: {
      tooltip: {
        enabled: false,
        display: false,
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  };

  const dataStart = new Date(dateStart);
  const dateStartFormat = format(dataStart, "yyyyMMdd");

  const dataEnd = new Date(dateEnd);
  const dateEndFormat = format(dataEnd, "yyyyMMdd");
  let rowsRange0 = rows.filter(
    (row) =>
      row.dimensionValues[4]?.value === "date_range_0" &&
      row.dimensionValues[0]?.value >= dateStartFormat &&
      row.dimensionValues[0]?.value <= dateEndFormat
  );
  let labelsRange0 = [];
  let dataPointsRange0 = [];
  if (rowsRange0.length > 0) {
    ({ labels: labelsRange0, dataPoints: dataPointsRange0 } = getLabelsAndData(
      rowsRange0,
      granularity,
      position
    ));
  } else {
    ({ labels: labelsRange0, dataPoints: dataPointsRange0 } = getLabelsAndData(
      rows,
      granularity,
      position
    ));
  }
  let datasets = [
    {
      data: labelsRange0.map((label, index) => ({
        x: label,
        y: dataPointsRange0[index],
        z: label,
      })),
      borderWidth: 1,
      borderColor: color[0],
      backgroundColor: color[0] + "33",
      fill: "origin",
    },
  ];
  let dataPoints = [];
  if (isComparing && dateStartCompare && dateEndCompare) {
    const dataStart = new Date(dateStartCompare);
    const dateStartFormat = format(dataStart, "yyyyMMdd");

    const dataEnd = new Date(dateEndCompare);
    const dateEndFormat = format(dataEnd, "yyyyMMdd");
    const rowsRange1 = rows.filter(
      (row) =>
        row.dimensionValues[4]?.value === "date_range_1" &&
        row.dimensionValues[0]?.value >= dateStartFormat &&
        row.dimensionValues[0]?.value <= dateEndFormat
    );
    let labels = [];

    if (rowsRange1.length > 0) {
      ({ labels, dataPoints } = getLabelsAndData(
        rowsRange1,
        granularity,
        position
      ));
    } else {
    }
    let labels_ = [];
    // add days different to labelsRange0
    const daydifferece = labelsRange0.length - dataPoints.length;
    if (daydifferece < 0) {
      // add days to labelsRange0
      const allDays = [];
      for (let i = 0; i < Math.abs(daydifferece); i++) {
        const day = format(
          addDays(labelsRange0[labelsRange0.length - 1], i + 1),
          "yyyy-MM-dd"
        );
        allDays.push(day);
        // labelsRange0.push(day);
        // dataPointsRange0.push(0);
      }
      labelsRange0 = [...labelsRange0, ...allDays];
      labels_ = labelsRange0;
    } else if (daydifferece > 0) {
      labels_ = labelsRange0;
    } else {
      labels_ = labelsRange0;
    }
    if (labels_.length > 0 && dataPoints.length > 0)
      datasets.push({
        data: labels_.map((label, index) => ({
          x: label,
          y: dataPoints[index],
          z: labels[index],
        })),
        borderColor: color[1],
        borderWidth: 1,
        backgroundColor: color[1] + "33",
        fill: "origin",
      });
  }
  const datasets_ = datasets.map((dataset) => {
    if (dataset.data.length < labelsRange0.length) {
      const allDays = [];
      for (let i = 0; i < labelsRange0.length - dataset.data.length; i++) {
        allDays.push({
          x: labelsRange0[dataset.data.length + i],
          y: 0,
          z: labelsRange0[dataset.data.length + i],
        });
      }
      dataset.data = [...dataset.data, ...allDays];
    }
    return dataset;
  });

  const data = {
    labels: labelsRange0,
    datasets: datasets_,
  };

  useEffect(() => {
    setGranularity(buttonSelected);
  }, [buttonSelected]);

  // calculate the total number of users
  const totalUsers = dataPointsRange0.reduce((acc, value) => acc + value, 0);

  // const {  dataPoints: dataPoints2 } = getLabelsAndData(
  //   rows,
  //   granularity,
  //   secondPosition
  // );

  const totalUsers2 = dataPoints.reduce((acc, value) => acc + value, 0);
  useEffect(() => {
    const item = pos.find(
      (item) => item.position === position && item.type === "line"
    );
    if (item) {
      item.value = media
        ? (totalUsers / dataPointsRange0.length).toFixed(2)
        : totalUsers;
      if (dataPoints.length > 0) {
        item.value2 = media
          ? (totalUsers2 / dataPoints.length).toFixed(2)
          : totalUsers2;
      } else {
        item.value2 = undefined;
      }
    }
    setPos([...pos]);
  }, [totalUsers2, totalUsers]);

  return <Line data={data} height={200} width={400} options={options}></Line>;
};

export default AnalyticsChart;
