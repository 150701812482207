import {
  startOfWeek,
  startOfMonth,
  subDays,
  differenceInDays,
  isSameDay,
  isWithinInterval,
  format,
} from "date-fns";

function determineIntervalType(start, end) {
  const now = new Date();
  const startFormatted = format(start, "yyyy-MM-dd");
  const endFormatted = format(end, "yyyy-MM-dd");
  // Check "today"
  if (isSameDay(start, end) && isSameDay(start, now)) {
    return "today";
  }

  // Check "yesterday"
  const yesterday = subDays(now, 1);
  if (isSameDay(start, end) && isSameDay(start, yesterday)) {
    return "yesterday";
  }

  // Check "last7Days"
  if (
    differenceInDays(endFormatted, startFormatted) === 7 &&
    startFormatted === format(subDays(now, 7), "yyyy-MM-dd") &&
    endFormatted === format(subDays(now, 0), "yyyy-MM-dd")
  ) {
    return "last7Days";
  }

  // Check "last30Days"
  if (
    differenceInDays(endFormatted, startFormatted) === 29 &&
    startFormatted === format(subDays(now, 30), "yyyy-MM-dd") &&
    endFormatted === format(subDays(now, 1), "yyyy-MM-dd")
  ) {
    return "last30Days";
  }

  // Check "lastWeek"
  const weekStart = startOfWeek(subDays(now, 7));
  if ( startFormatted === format(weekStart, "yyyy-MM-dd") &&
    endFormatted === format(subDays(now, 1), "yyyy-MM-dd")
  ) {
    return "lastWeek";
  }

  // Check "lastMonth"
  const monthStart = startOfMonth(subDays(now, 30));
  if (
    startFormatted === format(monthStart, "yyyy-MM-dd") &&
    endFormatted === format(subDays(now, 1), "yyyy-MM-dd")
  ) {
    return "lastMonth";
  }

  // Check "custom" interval: this case will be considered if no other condition matches
  if (isWithinInterval(now, { start, end })) {
    return "custom";
  }

  // If none of the above conditions match, return 'custom'
  return "custom";
}

export default determineIntervalType;
