import { useEffect } from "react";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Logo from "../assets/YourVisualData/logo contratto scritta nera.png";
import LogoEsteso from "../assets/YourVisualData/logo scritta nera.png";
import favicon from "../assets/YourVisualData/favicon.png";
import {
  GetProjects,
  insertProject,
  projectsState,
  LogoutCache,
} from "../utils/atom/projects";
import { useRecoilState } from "recoil";
import Loader from "./loader";
import { createParamsSearch } from "../utils/params";
import queryString from "query-string";

import imgprofilo from "../assets/imgprofilo.png";
import pushEventsToDataLayer from "../utils/gtm";

export default function Header({
  cookies,
  reference,
  getReferenceProps,
  setProfile,
  color,
  setColor,
}) {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [projects, setProjects] = useRecoilState(projectsState);

  const navigate = useNavigate();
  const router = useParams();
  const [loader, setLoader] = useState(true);

  const [, setToken] = useState(false);
  const [user, setUser] = useState({});
  const [image, setImage] = useState("");

  const handleImageError = () => {
    setImage(imgprofilo);
  };

  useEffect(() => {
    setLoader(true);
    const user_ = cookies.get("user");
    const token = cookies.get("jwt");
    if (!token || !user_) {
      cookies.remove("user");
      cookies.remove("jwt");
      cookies.remove("color");
      cookies.remove("positions");
      cookies.remove("Wizard");
      cookies.remove("Wizard_welcome");
      LogoutCache(setProjects, cookies);
      setToken(false);
      navigate("/login");
      return;
    } else {
      if (getTag(token)) {
        setUser(user_);
        setImage(user_.picture);
        setToken(true);
      }
      setLoader(false);
    }
  }, [router]);

  async function getTag(token) {
    const p = await GetProjects(false, projects, setProjects, token, cookies);
    if (p === "Insufficient Permission") {
      return true;
    }
    if (p) {
      checkProject(p);
      return true;
    } else {
      cookies.remove("user");
      cookies.remove("jwt");
      cookies.remove("color");
      cookies.remove("positions");
      cookies.remove("Wizard");
      cookies.remove("Wizard_welcome");
      LogoutCache(setProjects, cookies);
      // setOpenModal(true);
      navigate("/login");
      return false;
    }
  }
  // get url
  const url = window.location.pathname;
  const channel = url.split("/")[1];

  function containsOnlyNumbers(str) {
    const regex = /^\d+$/;
    return regex.test(str);
  }

  const checkProject = (projParsed) => {
    if (router.properties) {
      const selectedProject = projParsed.find(
        (account) => account.name === "accounts/" + router.properties
      );
      if (selectedProject) {
        setCurrentProject(selectedProject);
        if (router.project) {
          const selectedProperty = selectedProject.properties.find(
            (property) => property.name === "properties/" + router.project
          );
          if (selectedProperty) {
            setCurrentProperty(selectedProperty);
          } else {
            setCurrentProperty(selectedProject.properties[0]);
            if (containsOnlyNumbers(channel)) {
              navigate(
                `/visitors-overview/${selectedProject.name.split("/")[1]}/${
                  selectedProject.properties[0].name.split("/")[1]
                }`
              );
            } else {
              navigate(
                `/${channel}/${selectedProject.name.split("/")[1]}/${
                  selectedProject.properties[0].name.split("/")[1]
                }?${createParamsSearch(queryParams)}`
              );
            }
            return;
          }
        } else {
          setCurrentProperty(selectedProject.properties[0]);
          if (containsOnlyNumbers(channel)) {
            navigate(
              `/visitors-overview/${selectedProject.name.split("/")[1]}/${
                selectedProject.properties[0].name.split("/")[1]
              }`
            );
          } else {
            navigate(
              `/${channel}/${selectedProject.name.split("/")[1]}/${
                selectedProject.properties[0].name.split("/")[1]
              }?${createParamsSearch(queryParams)}`
            );
          }
          return;
        }
      } else {
        if (projParsed.length === 0) {
          navigate("/");
          return;
        }
        setCurrentProject(projParsed[0]);
        setCurrentProperty(projParsed[0].properties[0]);
        const prop = projParsed[0].name.split("/")[1];
        const id = projParsed[0].properties[0].name.split("/")[1];
        if (containsOnlyNumbers(channel)) {
          navigate(`/visitors-overview/${prop}/${id}`);
        } else {
          navigate(
            `/${channel}/${prop}/${id}?${createParamsSearch(queryParams)}`
          );
        }
        return;
      }
    } else {
      if (projParsed.length === 0) {
        return;
      }
      setCurrentProject(projParsed[0]);
      setCurrentProperty(projParsed[0].properties[0]);
      const prop = projParsed[0].name.split("/")[1];
      const id = projParsed[0].properties[0].name.split("/")[1];
      navigate(`/visitors-overview/${prop}/${id}`);
      return;
    }
    return;
  };

  const [currentProject, setCurrentProject] = useState(null);
  const [currentProperty, setCurrentProperty] = useState(null);
  const fetchAnalyticsProjects = async () => {
    const token = cookies.get("jwt");
    // const url = 'https://analytics.googleapis.com/v3/management/accountSummaries';
    if (!token) {
      navigate("/login");
      return;
    }
    setLoader(true);

    const url = process.env.REACT_APP_URL_BACKEND + "proprietas";
    await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        insertProject(data.accounts, setProjects, token);
        setCurrentProject(data.accounts[0]);
        setCurrentProperty(data.accounts[0].properties[0]);
        const prop = data.accounts[0].name.split("/")[1];
        const id = data.accounts[0].properties[0].name.split("/")[1];
        if (containsOnlyNumbers(channel)) {
          navigate(`/visitors-overview/${prop}/${id}`);
        } else {
          navigate(
            `/${channel}/${prop}/${id}?${createParamsSearch(queryParams)}`
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching Google Analytics projects:", error);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleChange = (e) => {
    const id = e.target.value;
    const selectedProject = projects?.pageCache["page"]?.projects.find(
      (project) => project.name === "accounts/" + id.split("/")[0]
    );
    const selectedProperty = selectedProject.properties.find(
      (property) => property.name === "properties/" + id.split("/")[1]
    );

    if (selectedProject && selectedProperty) {
      navigate(`/visitors-overview/${id}`);
      return;
    }
    if (selectedProject && !selectedProperty) {
      const currentProject = selectedProject.name.split("/")[1];
      const currentProperty = selectedProject.properties[0].name.split("/")[1];
      if (containsOnlyNumbers(channel)) {
        navigate(`/visitors-overview/${currentProject}/${currentProperty}`);
      } else {
        navigate(
          `/${channel}/${currentProject}/${currentProperty}?${createParamsSearch(
            queryParams
          )}`
        );
      }
      return;
    }
    if (containsOnlyNumbers(channel)) {
      navigate(
        `/visitors-overview/${projects[0].name.split("/")[1]}/${
          projects[0].properties[0].name.split("/")[1]
        }`
      );
    } else {
      navigate(
        `/${channel}/${projects[0].name.split("/")[1]}/${
          projects[0].properties[0].name.split("/")[1]
        }?${createParamsSearch(queryParams)}`
      );
    }
  };
  useEffect(() => {
    try {
      const c = JSON.stringify(color);
      cookies.set("color", c);
    } catch {
      cookies.set("color", ["#058dc7", "#ed7e17"]);
    }
  }, [color]);

  const buttonColor = [
    {
      name: "Classic",
      color: ["#058dc7", "#ed7e17", "#c6e9f9", "#ed7e1733"],
    },
    {
      name: "Rock",
      color: ["#DB79CB", "#837BDB", "#7FDCD4", "#D1F0D8"],
    },
    {
      name: "Jazz",
      color: ["#096F26", "#982649", "#F4E5E2", "#EDF7DE"],
    },
  ];

  return (
    <header
      className=" top-0 bg-white z-50 border-b remove-border-no-bottom border-lightgray fixed w-full border-solid"
      style={{
        borderTopWidth: "0px",
        borderLeftWidth: "0px",
        borderRightWidth: "0px",
      }}
    >
      <div>
        <div className="flex items-center justify-between h-[60px] px-4 md:px-[10px] lg:px-[40px] gap-4">
          <div>
            <div
              className="block lg:hidden cursor-pointer"
              onClick={() => {
                navigate(
                  "/visitors-overview/" +
                    currentProject.name.split("/")[1] +
                    "/" +
                    currentProperty.name.split("/")[1]
                );
              }}
            >
              <LazyLoadImage src={favicon} width="40" />
            </div>
            <div
              className="hidden lg:flex cursor-pointer  items-end gap-1 w-fit"
              onClick={() => {
                navigate(
                  "/visitors-overview/" +
                    currentProject.name.split("/")[1] +
                    "/" +
                    currentProperty.name.split("/")[1]
                );
              }}
            >
              <LazyLoadImage src={LogoEsteso} width="150" />
              <span className="text-[10px] whitespace-nowrap h-[17px]">
                | Powered by Fortop
              </span>
            </div>
          </div>
          {!loader ? (
            <div className="flex gap-2 md:gap-8 items-center justify-between  w-full">
              {projects.pageCache["page"]?.projects.length === 0 ? (
                <div className="text-gray-500 ml-10">
                  You have no projects associated with this account
                </div>
              ) : currentProperty ? (
                <div className="flex gap-2 md:gap-4 items-center justify-between w-full ml-10">
                  <div
                    className="flex gap-2 md:gap-4 items-center justify-start p-2"
                    id="project-property"
                  >
                    <div className="flex items-center gap-2 ">
                      <span className="text-gray-500 hidden xl:block">
                        Account:
                      </span>
                      <select
                        onClick={() => {
                          pushEventsToDataLayer({
                            event: "account_select",
                          });
                        }}
                        className="border border-gray-300 rounded-md p-2 max-w-[250px]"
                        onChange={handleChange}
                        value={currentProject.name.split("/")[1]}
                      >
                        {projects?.pageCache["page"]?.projects.map(
                          (project) => (
                            <option
                              key={project.name}
                              value={`${project.name.split("/")[1]}`}
                            >
                              {project.displayName}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-gray-500 hidden xl:block">
                        Property:
                      </span>
                      <select
                        onClick={() => {
                          pushEventsToDataLayer({
                            event: "property_select",
                          });
                        }}
                        value={
                          currentProject.name.split("/")[1] +
                          "/" +
                          currentProperty.name.split("/")[1]
                        }
                        className="border border-gray-300 rounded-md p-2 max-w-[250px]"
                        onChange={handleChange}
                      >
                        {currentProject.properties.map((property) => (
                          <option
                            key={property.name}
                            value={
                              currentProject.name.split("/")[1] +
                              "/" +
                              property.name.split("/")[1]
                            }
                          >
                            {property.displayName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div
                    className="flex gap-2 items-center p-2"
                    id="display-mode"
                  >
                    <span className="text-gray-500 hidden xl:block">
                      Display mode:
                    </span>
                    {buttonColor.map((button, index) => (
                      <button
                        key={index}
                        className={`rounded-xl ${
                          color[0] === button.color[0]
                            ? "border-2 border-black px-2 py-1"
                            : "border-2 border-white hover:border-black px-2 py-1"
                        }`}
                        onClick={() => {
                          pushEventsToDataLayer({
                            event: 'display_selected',
                            "data-click-text": button.name?.toLowerCase(),
                          });
                          setColor(button.color);
                        }}
                      >
                        {button.name}
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="text-gray-500 ml-10">
                  <Loader />
                </div>
              )}
              {user && image && (
                <img
                  onClick={() => {
                    setProfile(true);
                  }}
                  ref={reference}
                  {...getReferenceProps()}
                  src={image}
                  alt="profile"
                  className="w-8 h-8 rounded-full cursor-pointer"
                  onError={handleImageError}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </header>
  );
}
