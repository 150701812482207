import ReactDOM from 'react-dom/client';
import './index.css'; 
// import 'animate.css';
import { AppMain } from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  // <React.StrictMode>
  <AppMain />
  // </React.StrictMode>
);

reportWebVitals();