import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink } from 'react-router-dom';
import SchermataLogin from '../assets/YourVisualData/LoginSchermata.png';
import mobiletopc from '../assets/mobiletoPc.svg';
import LogoEsteso from '../assets/YourVisualData/logo nero scritta nera estesa.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = ({ width, cookies }) => {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'Page Not Found | YourVisualData';
    }
        , []);

    useEffect(() => {
        if (width <= 750) {
            if (!cookies.get('jwt')) {
                return navigate("/login");
            }
            return
        }
    }, [])

    return (
        <div>
            {width <= 750 ?
                <div className='bg-no-repeat bg-cover  bg-center w-full h-screen' style={{ backgroundImage: `url("${SchermataLogin}")` }}>
                    <div className='h-[80vh]  w-[80%]  mx-auto bg-white absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 rounded-[12px] px-[10%] py-[5vh]' >
                        <LazyLoadImage src={mobiletopc} className="w-full" />
                        <h2 className='text-primaryGray mt-4'>Hello, we are happy that you are using our dashboard.</h2>
                        <h5 className='text-primaryGray my-4'>But don't worry, you can access the full version from a computer. <br /> <br />We promise to get to work to make the mobile version available as soon as possible. Thank you for your patience and support!</h5>
                        <a href="https://www.yourvisualdata.com"
                            target="_blank"
                            rel="noreferrer noopener"
                            className='w-full flex items-center justify-center'
                        >
                            <div
                                className="mt-4  rounded-md p-2 w-fit
                                bg-gray-200
                                hover:bg-gray-300"
                            >
                                <span className="inline-block "><h3>Go to WebSite </h3></span>
                            </div>
                        </a>
                        <LazyLoadImage src={LogoEsteso} className="w-[200px] mt-[3vh]" />
                    </div>
                </div>
                :
                <div className='flex flex-col items-center justify-center h-[100vh] w-full'>
                    <div className=' '>
                        <LazyLoadImage src={LogoEsteso} className="w-[260px]" />
                    </div>
                    <div className='text-center mx-auto mt-4'>
                        <h1 className=' text-black mb-[38px]'>Oops… we couldn't find the  <br />page you were looking for</h1>
                        <NavLink to="/">
                            <div
                                className="mt-4  rounded-md p-2
                                bg-gray-200
                                hover:bg-gray-300"
                            >
                                <span className="inline-block "><h3>Back to Home</h3></span>
                            </div>
                        </NavLink>
                    </div>
                </div>}
        </div>
    )
}
export default Home;