import React from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
  ChartDataLabels
);

const AnalyticsChart = ({ rows, color }) => {
  // Sample data from the provided output
  const labels = ["New Visitor", "Returning Visitor"];

  const users = rows?.map((row) => parseFloat(row.metricValues[0].value, 10));
  const totalUsers = users.reduce((sum, current) => sum + current, 0);

  const newUsers = rows?.map((row) => parseFloat(row.metricValues[1].value, 10));
  const totalNewUsers = newUsers.reduce((sum, current) => sum + current, 0);

  // Create the data object for Chart.js
  const data = {
    labels: labels,
    datasets: [
      {
        data: [totalNewUsers, totalUsers - totalNewUsers],
        backgroundColor: [color[0], color[1] === "#ed7e17" ? "#50b432" : color[1]],
        hoverOffset: 2,
      },
    ],
  };

  return (
    <Pie
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
          datalabels: {
            formatter: (value, context) => {
              const sum = context.dataset.data.reduce((a, b) => a + b, 0);
              const percentage = ((value / sum) * 100).toFixed(2) + "%";
              return percentage;
            },
            color: "#fff",
            font: {
              weight: "bold",
              size: 10,
            },
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                const data = tooltipItem.dataset.data;
                const total = data.reduce((sum, val) => sum + val, 0);
                const value = data[tooltipItem.dataIndex];
                const percentage = ((value / total) * 100).toFixed(2) + "%";
                return `${value} Users (${percentage})`;
              },
              labelTextColor: function (context) {
                return "#000";
              },
              
            },
            displayColors: false,
            backgroundColor: "#fff",
            titleFont: {
              size: 10,
            },
            bodyFont: {
              size: 8,
              weight: "bold",
            },
          },
        },
      }}
    />
  );
};

export default AnalyticsChart;
