import React from "react";
import pushEventsToDataLayer from "./gtm";
// eslint-disable-next-line react-hooks/rules-of-hooks
const steps = [
  {
    id: "intro",
    scrollTo: true,
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        setTimeout(function () {
          window.scrollTo(0, 0);
          resolve();
        }, 500);
      });
    },
    buttons: [
      {
        classes: "button-next-w",
        text: "Next",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "welcome-to-your-data-analytics",
            "data-click-text": "next",
          });
          this.next();
        },
      },
      {
        classes: "button-cancel",
        text: "Skip",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "welcome-to-your-data-analytics",
            "data-click-text": "skip",
          });
          this.cancel();
        },
      },
      {
        classes: "shepherd-cancel-icon",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "welcome-to-your-data-analytics",
            "data-click-text": "close",
          });
          this.cancel();
        },
      },
    ],
    highlightClass: "highlight",
    // cancelIcon: {
    //   enabled: true,
    // },
    title: "Welcome to <br/>Your Data Analytics.",
    text: ["Enjoy this tour of the Dashboard."],
  },
  {
    id: "project-property-step",
    attachTo: {
      element: "#project-property",
      on: "bottom",
    },
    classes: "mt-[26px]",
    title: "Project and Property",
    text: ["Choose a project and a property to start analyzing your data"],
    buttons: [
      {
        classes: "button-next-w",
        text: "Next",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "project-and-property",
            "data-click-text": "next",
          });
          this.next();
        },
      },
      {
        classes: "button-cancel",
        text: "Skip",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "project-and-property",
            "data-click-text": "skip",
          });
          this.cancel();
        },
      },
      {
        classes: "shepherd-cancel-icon",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "project-and-property",
            "data-click-text": "close",
          });
          this.cancel();
        },
      },
    ],
    // cancelIcon: {
    //   enabled: true,
    // },
    highlightClass: "highlight",
  },
  {
    id: "compare-data-step",
    attachTo: {
      element: "#compare-data",
      on: "bottom",
    },
    classes: "mt-[26px]",
    title: "Time Range",
    text: ["Choose a time range and compare your data"],
    buttons: [
      {
        classes: "button-next-w",
        text: "Next",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "time-range",
            "data-click-text": "next",
          });
          this.next();
        },
      },
      {
        classes: "button-cancel",
        text: "Skip",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "time-range",
            "data-click-text": "skip",
          });
          this.cancel();
        },
      },
      {
        classes: "shepherd-cancel-icon",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "time-range",
            "data-click-text": "close",
          });
          this.cancel();
        },
      },
    ],
    // cancelIcon: {
    //   enabled: true,
    // },
    highlightClass: "highlight",
  },
  {
    id: "sidebar-step",
    attachTo: {
      element: "#sidebar",
      on: "left",
    },
    classes: "ml-[26px]",
    title: "This is the sidebar",
    text: ["Here you will find all the sections of the Dashboard."],
    buttons: [
      {
        classes: "button-next-w",
        text: "Next",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "this-is-the-sidebar",
            "data-click-text": "next",
          });
          this.next();
        },
      },
      {
        classes: "button-cancel",
        text: "Skip",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "this-is-the-sidebar",
            "data-click-text": "skip",
          });
          this.cancel();
        },
      },
      {
        classes: "shepherd-cancel-icon",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "this-is-the-sidebar",
            "data-click-text": "close",
          });
          this.cancel();
        },
      },
    ],
    // cancelIcon: {
    //   enabled: true,
    // },
    highlightClass: "highlight",
  },
  {
    id: "display-mode-step",
    attachTo: {
      element: "#display-mode",
      on: "bottom",
    },
    classes: "mt-[26px]",
    title: "Display Mode",
    text: ["Choose the display mode of the data."],
    buttons: [
      {
        classes: "button-next-w",
        text: "Complete",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "click-text",
            "data-click-text": "complete",
          });
          this.complete();
        },
      },
      {
        classes: "shepherd-cancel-icon",
        action: function () {
          pushEventsToDataLayer({
            'event': "wizard_click",
            "data-step": "click-text",
            "data-click-text": "close",
          });
          this.cancel();
        },
      },
    ],
    // cancelIcon: {
    //   enabled: true,
    // },
    highlightClass: "highlight",
  },
];
export default steps;
