import { atom, selector } from "recoil";


const isDevelopment = process.env.NODE_ENV === "development";
const atomKey = isDevelopment ? `allProjects-${Date.now()}` : "allProjects";

//Create new atom for all projects
export const allProjectsState = atom({
    key: atomKey,
    default: {
        pageCache: {},
        expireDate: 0,
        error: false
    },
});

export const projectsState = selector({
    key: "allProjectsState",
    get: async ({ get }) => get(allProjectsState),
    set: ({ set }, newValue) => {
        set(allProjectsState, newValue);
    },
});

export const GetProjects = async (forceRefresh = false, projects, setProjects, token, cookies) => {

    const cacheKey = `page`;
    const cachedData = projects.pageCache[cacheKey];

    if (!forceRefresh && cachedData && cachedData.expireDate > new Date().getTime()) {
        // Return cached data if it exists and has not expired
        if (cachedData.projects.length) {
            cookies.set('Wizard', 'true');
        }
        return cachedData.projects;
    }
    try {
        const url = process.env.REACT_APP_URL_BACKEND + "proprietas";
        const projectFetched = await fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => response.json())
            .then(async (data) => {
                return data;
            })
            .catch((error) => {
                console.error(error, "Error in fetching projects");
                return [];
            });
        if (!projectFetched.error) {
            if (projectFetched?.accounts.length) {
                cookies.set('Wizard', 'true');
            }

            const newCache = {
                projects: projectFetched?.accounts || [],
                expireDate: new Date().getTime() + 600000, // Cache expiration time (in this case, 2 minutes)
            };
            setProjects((prev) => ({
                ...prev,
                pageCache: {
                    ...prev.pageCache,
                    [cacheKey]: newCache, // Save new cache to the corresponding page key
                },
                expireDate: new Date().getTime() + 600000, // Update global cache expiration time
            }));
            return newCache.projects;
        } else {
            if (projectFetched.error === "Insufficient Permission") {
                setProjects((prev) => ({
                    ...prev,
                    error: true,
                }));
                return "Insufficient Permission";
            }
            return false
        }
    } catch (error) {
        console.error(error, "Error in fetching projects");
        return [];
    }
};
export const insertProject = async (project, setProjects) => {
    //To delete cache and get fresh data.
    //TODO:
    GetProjects(true, project, setProjects);
};
export const LogoutCache = (setProjects, cookies) => {
    // Clear cookies
    cookies.remove('Wizard');

    // Reset projects state
    setProjects({
        pageCache: {},
        expireDate: 0,
    });
};
