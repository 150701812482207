import { useEffect, useState, useCallback } from "react";
import { instagramCheck } from "../utils/atom/instagram";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import Modal from "../components/modalError";
import LogoEsteso from "../assets/YourVisualData/logo nero scritta nera estesa.png";
import LogoContratto from "../assets/YourVisualData/logo scritta nera.png";
import SchermataLogin from "../assets/YourVisualData/LoginSchermata-min.png";
import BoxLogin from "../assets/YourVisualData/BoxLogin3.png";

import pushEventsToDataLayer from "../utils/gtm";
import Logo from "../assets/YourVisualData/favicon.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const GoogleAnalyticsProjects = ({ cookies }) => {
  useEffect(() => {
    document.title = "Login | YourVisualData";
  }, []);

  const [instagram, setInstagram] = useRecoilState(instagramCheck);
  const [thereIsTheInstagramFeed, setThereIsTheInstagramFeed] = useState(false);
  const AuthLocal = async () => {
    setThereIsTheInstagramFeed(false);
    setInstagram(false);

    const url = process.env.REACT_APP_URL_BACKEND + "auth/local";

    try {
      const response = await fetch(url, { method: "POST" });
      const data = await response.json();

      if (data.client && data.client.url) {
        setThereIsTheInstagramFeed(true);
        return data.client.url;
      }

      return null; // Nessun URL trovato
    } catch (error) {
      console.error("Error fetching data:", error);
      return null; // Gestisci l'errore, nessun URL
    }
  };

  const openPage = (url) => {
    // Usa setTimeout per garantire che la finestra venga aperta in modo sincrono
    setTimeout(() => {
      if (url) {
        window.open(url, "_blank");
      } else {
        console.error("URL non valido.");
      }
    }, 0); // Ritardo minimo
  };

  const LoginFunction = async () => {
    pushEventsToDataLayer({
      event: "user_login",
    });
    // Ottieni l'URL dall'operazione asincrona
    const url = await AuthLocal();

    // Apri la pagina con il ritardo minimo
    openPage(url);
  };

  const navigate = useNavigate();

  const getInstagramFeed = useCallback(() => {
    if (cookies.get("jwt")) {
      navigate("/");
    }
  });

  useEffect(() => {
    if (thereIsTheInstagramFeed) {
      getInstagramFeed();
    }
  }, [thereIsTheInstagramFeed]);

  useEffect(() => {
    let intervalId;

    if (instagram === false) {
      intervalId = setInterval(() => {
        if (thereIsTheInstagramFeed) {
          getInstagramFeed();
        }
      }, 100);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [getInstagramFeed, instagram, thereIsTheInstagramFeed]);

  useEffect(() => {
    const token = cookies.get("jwt");
    if (token) {
      window.location.href = "/";
      return;
    }
  }, []);
  const [modalError, setModalError] = useState(false);

  return (
    <div className="flex items-center justify-between h-screen flex-row-reverse">
      <div className="w-[60%] h-full md:block hidden">
        <div
          src={SchermataLogin}
          style={{
            backgroundImage: `url(${SchermataLogin})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="w-full h-full object-cover "
        >
          <div className="p-10 xl:p-16 flex items-end gap-2">
            <img src={LogoEsteso} alt="Logo" className=" h-[37px] w-[233px] " />
            <span className="text-[11px] whitespace-nowrap h-[20px]">
              | Powered by Fortop
            </span>
          </div>
          <div className="w-full  flex flex-col p-10 xl:p-16 pt-0 xl:pt-0 2xl:pt-10 justify-center">
            <div className="flex flex-col ">
              <h1 className="xl:text-[64px] text-[40px] text-black font-semibold">
                Your data is
                <span className="font-semibold italic ml-3">safe</span>.
              </h1>
              <div className="mt-2 text-[18px] ">
                <div className="flex flex-col gap-14">
                  <h2 className="text-black font-regular leading-6">
                    With
                    <span className="font-semibold mx-1">Your Visual Data</span>
                    your information <br /> is completely secure. We don't store
                    <br />
                    any property data in our systems.
                  </h2>
                  <h3 className="xl:text-[34px] text-[20px] text-black font-semibold leading-6 lg:leading-10">
                    Three simple steps to achieve your <br />
                    Universal Data Visualization.
                  </h3>
                </div>
                <img className="mt-4" src={BoxLogin} alt="BoxLogin" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2  flex items-center justify-center h-screen relative">
        <div className="w-88 p-5 text-center flex items-center justify-center flex-col ">
          <img
            src={Logo}
            alt="Logo YourVisualData"
            className="w-14 h-14 mx-auto mb-10 md:block hidden"
          />
          <div className="flex flex-col  mx-auto mb-10 md:hidden items-end">
            <LazyLoadImage src={LogoContratto} width="200" />
            <span className="text-[10px] whitespace-nowrap mt-[-3px]">
              | Powered by Fortop
            </span>
          </div>
          <h1 className="text-center text-4xl mb-3 font-semibold">Welcome</h1>
          <h2 className="text-[#00000077] font-regular  mb-6">
            Login with Google to get started.
          </h2>
          <button
            className="w-80 flex items-center justify-center p-3 bg-white text-gray-700 border border-gray-300 rounded-lg hover:bg-gray-100
            font-semibold text-lg
            "
            onClick={() => LoginFunction()}
          >
            <img
              src="https://developers.google.com/identity/images/g-logo.png"
              alt="Google Logo"
              className="w-6 h-6 mr-2"
            />
            Login In with Google
          </button>
          <div className="bottom-20 absolute w-full text-center">
            <h4 className="text-sm text-[#00000077] mt-2 block text-center">
              By login in with Google you accept our <br />
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer noopener"
                className="text-black underline"
              >
                Privacy Policy
              </a>{" "}
              and our{" "}
              <a
                href="/terms-and-conditions"
                target="_blank"
                rel="noreferrer noopener"
                className="text-black underline"
              >
                Terms and Conditions
              </a>
            </h4>
          </div>
        </div>
      </div>
      {modalError && (
        <Modal
          modalIsOpen={modalError}
          closeModal={() => setModalError(false)}
          message="Non è stato possibile accedere a Google"
        />
      )}
    </div>
  );
};

export default GoogleAnalyticsProjects;
