import { FloatingFocusManager } from "@floating-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogoutCache, projectsState } from "../utils/atom/projects";
import { useRecoilState } from "recoil";
import pushEventsToDataLayer from "../utils/gtm";

export default function PopoverSIdeBar(props) {
  const [profile, setProfile] = useState({});
  const getProfile = () => {
    const user_ = props.cookies.get("user");
    setProfile(user_);
  };
  const navigate = useNavigate();

  const logout = async () => {
    pushEventsToDataLayer({
      'event': "user_logout"
    });
    const user_ = props.cookies.get("user");
    if (user_ && user_.id) {
      const response = await fetch(
        process.env.REACT_APP_URL_BACKEND + "users/" + user_.id,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${props.cookies.get("jwt")}`,
          },

          body: {},
        }
      );
      await response.json();
    }
    LogoutCache(setProjects, props.cookies);
    props.cookies.remove("user");
    props.cookies.remove("jwt");
    props.cookies.remove("color");
    props.cookies.remove("positions");
    props.cookies.remove("Wizard");
    props.cookies.remove("Wizard_welcome");
    navigate("/login");
  };
  useEffect(() => {
    getProfile();
  }, []);

  const [, setProjects] = useRecoilState(projectsState);

  const deleteUser = async () => {
    pushEventsToDataLayer({
      'event': 'user_delete_account'
      });
    const user_ = props.cookies.get("user");
    if (!user_ || !user_.id) {
      alert("Impossibile eliminare l'account, riprova");
      return;
    }
    const response = await fetch(
      process.env.REACT_APP_URL_BACKEND + "users/" + user_.id,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${props.cookies.get("jwt")}`,
        },

        body: {},
      }
    );
    const data = await response.json();
    if (data && data.message === "Utente eliminato correttamente") {
      LogoutCache(setProjects, props.cookies);
      props.cookies.remove("user");
      props.cookies.remove("jwt");
      props.cookies.remove("color");
      props.cookies.remove("positions");
      props.cookies.remove("Wizard");
      props.cookies.remove("Wizard_welcome");
      navigate("/login");
    } else {
      // allert
      alert("Impossibile eliminare l'account, riprova");
    }
  };

  return (
    <div className="w-100vh">
      <FloatingFocusManager context={props.context} modal={false}>
        {props.open ? (
          <div
            className={
              props.open
                ? "animate__animated animate__fadeIn animate__faster Popover bg-white mr-4 p-6 py-8 rounded-2xl shadow-md  items-center "
                : "animate__animated animate__fadeOut animate__faster Popover bg-white ml-4 p-6 py-8 rounded-2xl shadow-md flex items-center"
            }
            ref={props.floating}
            style={{
              position: "fixed",
              top: 50,
              right: props.profile ? 150 : 0,
              zIndex: 1000,
              width: "350px",
              outline: "none",
              background: "white",
            }}
            aria-labelledby={props.headingId}
            {...props.getFloatingProps()}
          >
            <h3 id={props.headingId} className="inline pb-6 capitalize">
              {profile.full_name}
            </h3>
            <h3>{profile.email}</h3>
            <div className="flex inline gap-4">
              <button
                className="mt-4  rounded-md p-2
                bg-gray-200
                hover:bg-gray-300
              "
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </button>
              <button
                className="mt-4  rounded-md p-2
                bg-red-200
                hover:bg-red-300
              "
                onClick={() => {
                  deleteUser();
                }}
              >
                Delete account
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </FloatingFocusManager>
    </div>
  );
}
