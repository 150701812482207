import React, {useEffect} from 'react';
import Logo from "../assets/YourVisualData/logo scritta nera.png";
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const TermsAndConditions = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Terms and Conditions | YourVisualData';
  }
    , []);
  return (
    <>
      <header
        className=" top-0 bg-white z-50 border-b remove-border-no-bottom border-lightgray fixed w-full border-solid"
        style={{
          borderTopWidth: "0px",
          borderLeftWidth: "0px",
          borderRightWidth: "0px",
        }}
      >
        <div>
          <div className="flex items-center justify-between h-[60px] px-4 md:px-[40px]">
            <div>
            <div
              className="flex cursor-pointer  items-end gap-1 w-fit"
              onClick={() => {
                navigate("/");
              }}
            >
              <LazyLoadImage src={Logo} width="150" />
              <span className="text-[10px] whitespace-nowrap h-[17px]">
                | Powered by Fortop
              </span>
            </div>
            </div>
          </div>
        </div>
      </header>

      <div className="max-w-4xl mx-auto p-6 mt-20">
        <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">1. Contents of this Website</h2>
          <p className="mb-2">
            Yourvisualdata.com is an application for displaying your data developed by Fortop s.r.l., Milan (MI), Corso Sempione, 10.
          </p>
          <p className="mb-2">
            The structure of this site, the images of the pages that compose it and the elements present in the pages themselves (for example texts, static images, animations, sounds, videos) are the property of Yourvisualdata.com and may not be reproduced (in whole or in part) or transmitted without the written permission of the aforementioned subject.
          </p>
          <p className="mb-2">
            The above limitation also applies to the HTML description of the pages on the Website.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Software available on this site</h2>
          <p className="mb-2">
            Any use of the software downloadable from this site is subject to acceptance of the terms of any license agreement with the end user included in the software itself. The software on this website that can be used directly without being downloaded is intended exclusively for visitors to the site itself and must not in any way be integrated or referenced in software made without the written permission of the aforementioned owner.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Links to other websites</h2>
          <p className="mb-2">
            This Website may contain links to other Websites. The sites to which they refer these links are not under the control of Yourvisualdata.com which, therefore, is not responsible for their content.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Terms of Use</h2>
          <p className="mb-2">
            Yourvisualdata.com is a free and freely usable beta throughout its lifecycle.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Contacts</h2>
          <p className="mb-2">info@yourvisualdata.com</p>
        </section>
      </div>
    </>
  );
};

export default TermsAndConditions;
