import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "../assets/YourVisualData/logo scritta nera.png";

const CookieDeclaration = () => {
  useEffect(() => {
    // Crea un nuovo elemento script
    const script = document.createElement("script");
    script.id = "CookieDeclaration";
    script.src =
      "https://consent.cookiebot.com/b37a5e87-af5f-44f1-a9c8-771b7eb97021/cd.js";
    script.type = "text/javascript";
    script.async = true;

    // Appendi lo script all'elemento con id "CookiePolicy"
    const scriptContainer = document.getElementById("CookiePolicy");
    if (scriptContainer) {
      scriptContainer.appendChild(script);
    }

    // Cleanup per rimuovere lo script quando il componente viene smontato
    return () => {
      if (scriptContainer) {
        scriptContainer.removeChild(script);
      }
    };
  }, []); // L'array vuoto assicura che l'effetto venga eseguito solo una volta al montaggio del componente

  const navigate = useNavigate();
  return (
    <>
      <header
        className=" top-0 bg-white z-50 border-b remove-border-no-bottom border-lightgray fixed w-full border-solid"
        style={{
          borderTopWidth: "0px",
          borderLeftWidth: "0px",
          borderRightWidth: "0px",
        }}
      >
        <div>
          <div className="flex items-center justify-between h-[60px] px-4 md:px-[40px]">
            <div>
              <div
                className="flex cursor-pointer  items-end gap-1 w-fit"
                onClick={() => {
                  navigate("/");
                }}
              >
                <LazyLoadImage src={Logo} width="150" />
                <span className="text-[10px] whitespace-nowrap h-[17px]">
                  | Powered by Fortop
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div id="CookiePolicy" className="p-20">
        {/* Lo script sarà aggiunto qui */}
      </div>
    </>
  );
};

export default CookieDeclaration;
