import React from "react";
import { format, addDays, subDays, startOfWeek, startOfMonth } from "date-fns";

const DateSelector = ({ setTypeSelected, typeSelected, setValues, date }) => {
  const handleSelectChange = (event) => {
    const value = event.target.value;
    let start, end;

    switch (value) {
      case "custom":
        start = addDays(new Date(), -30);
        end = new Date();
        break;
      case "today":
        start = end = new Date();
        break;
      case "yesterday":
        start = end = subDays(new Date(), 1);
        break;
      case "lastWeek":
        start = startOfWeek(subDays(new Date(), 7));
        end = subDays(new Date(), 1);
        break;
      case "lastMonth":
        start = startOfMonth(subDays(new Date(), 30));
        end = subDays(new Date(), 1);
        break;
      case "last7Days":
        start = subDays(new Date(), 7);
        end = new Date();
        break;
      case "last30Days":
        start = subDays(new Date(), 30);
        end = subDays(new Date(), 1);
        break;
      default:
        start = value;
        setTypeSelected(value);
        setValues(start);
        return value
    }
    start = format(start, "yyyy-MM-dd");
    end = format(end, "yyyy-MM-dd");
    setTypeSelected(value);
    setValues([start, end]);
    return value;
  };

  return (
    <div>
      <select onChange={handleSelectChange} value={typeSelected}>
        {date.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DateSelector;
