
import React from "react";
import "../App.css";

const Loader = () => {
    return (
        <div className=" flex justify-center items-center ">
            <div className="loader"></div>
        </div>
    )
}

export default Loader;