import Modal from "react-modal";

export default function ModaleChatGPT(props) {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px 20px 20px 20px",
      maxHeight: "90vh",
      overflow: "hidden",
    },
    overlay: {
      background: "rgba(193, 255, 114, 0.2)",
    },
  };

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={"w-[300px] h-[300px] overflow-y-auto "}>
        <svg
          onClick={() => props.closeModal()}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#233a3a"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="float-right absolute right-10 top-10 cursor-pointer "
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M18 6l-12 12" />
          <path d="M6 6l12 12" />
        </svg>
        <div className="pt-8 ">
          <div className={"flex flex-col items-center justify-center my-4"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="red"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
              <path d="M12 9v4" />
              <path d="M12 16v.01" />
            </svg>
            <div className="flex flex-col items-center px-8 text-center mt-4">
              <h3 className="text-primaryColor">{props.message}</h3>
              <h4 className="text-xs mt-4 mb-2">
                Se il problema persiste contatta l'assistenza tecnica
              </h4>
              <a
                href="mailto:info@yourvisualdata.com"
                className="text-primaryColor underline cursor-pointer text-xs"
              >
                info@yourvisualdata.com
              </a>
              {props.logout && (
                <button
                  onClick={() => {
                    props.logoutFunction();
                    props.closeModal();
                  }}
                  className="mt-2  rounded-md p-2 bg-gray-200 hover:bg-gray-300"
                >
                  Logout
                </button>
              )}
              {props.remove && (
                <p className="text-red-500 text-center">
                  <a
                    className="underline "
                    target="_blank"
                    rel="noreferrer noopener"
                    onClick={() => {
                      // close page
                      window.close();
                    }}
                    href="https://myaccount.google.com/connections?filters=3,4&hl=it&pli=1"
                  >
                    Rimuovi l'accesso all'applicazione One Click
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
