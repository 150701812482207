import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import AnalyticsChart from "../components/lineChart";
import { addDays, format } from "date-fns";
import Loader from "../components/loader";
import Modal from "../components/modalError";
import ComparedatePicker from "../components/ComparedatePicker";
import { projectsState, LogoutCache } from "../utils/atom/projects";
import { useRecoilState } from "recoil";
import queryString from "query-string";
import pushEventsToDataLayer from "../utils/gtm";
import determineIntervalType from "../utils/periodata";

function containsOnlyNumbers(str) {
  const regex = /^\d+$/;
  return regex.test(str);
}

const initialData = [
  { id: "1", type: "main", title: "Users", position: 0, value: undefined },
];

const GoogleAnalyticsProjects = ({ cookies, color, setColor }) => {
  const router = useParams();
  useEffect(() => {
    document.title = "Channels | YourVisualData";
  }, []);

  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [dateStartCompare, setDateStartCompare] = useState(null);
  const [dateEndCompare, setDateEndCompare] = useState(null);

  const [isComparing, setIsComparing] = useState(false);

  const allMetric = [
    { name: "Users", id: 0, value: "totalUsers" },
    { name: "New Users", id: 1, value: "newUsers" },
    { name: "Sessions", id: 2, value: "sessions" },
    { name: "Bounce Rate", id: 7, value: "bounceRate" },
    { name: "Pages/Session", id: 5, value: "screenPageViewsPerSession" },
    { name: "Avg. Session Duration", id: 6, value: "averageSessionDuration" },
  ];
  const secondMetric = [
    { name: "Select a metric", id: -1, value: undefined },
    { name: "Users", id: 0, value: "totalUsers" },
    { name: "New Users", id: 1, value: "newUsers" },
    { name: "Sessions", id: 2, value: "sessions" },
    { name: "Bounce Rate", id: 7, value: "bounceRate" },
    { name: "Pages/Session", id: 5, value: "screenPageViewsPerSession" },
    { name: "Avg. Session Duration", id: 6, value: "averageSessionDuration" },
  ];

  const [dataLine, setDataLine] = useState(0);
  const [dataLineSecond, setDataLineSecond] = useState(-1);

  const [loader, setLoader] = useState(true);
  const [loaderTablle, setLoaderTable] = useState(true);

  const fetchAnalyticsProjects = async (
    start,
    end,
    startCompare,
    endCompare,
    compare
  ) => {
    const id = router.project;

    if (!containsOnlyNumbers(id)) {
      navigate("/");
      return;
    }
    setLoader(true);
    setModalError(false);
    const token = cookies.get("jwt");
    if (!token) {
      navigate("/login");
      return;
    }
    const startDate = start;
    const endDate = end;

    // check dateStart and dateEnd is date format
    if (!startDate || !endDate) {
      setLoader(false);
      // todo
      return;
    }
    const dateRanges = [
      {
        startDate: startDate,
        endDate: endDate,
      },
    ];

    if (compare) {
      setIsComparing(true);
      const startDateCompare = startCompare;
      const endDateCompare = endCompare;
      if (!startDateCompare || !endDateCompare) {
        setLoader(false);
        // todo
        return;
      }

      dateRanges.push({
        startDate: startDateCompare,
        endDate: endDateCompare,
      });
      setDateStartCompare(startDateCompare);
      setDateEndCompare(endDateCompare);
    } else {
      setIsComparing(false);
    }

    const data = {
      query: {
        metrics: [
          { name: "totalUsers" },
          { name: "newUsers" },
          { name: "sessions" },
          { name: "sessionsPerUser" },
          { name: "screenPageViews" },
          { name: "screenPageViewsPerSession" },
          { name: "averageSessionDuration" },
          { name: "bounceRate" },
        ],
        dateRanges: dateRanges,
        dimensions: [
          {
            name: "date",
          },
          {
            name: "hour",
          },
          {
            name: "month",
          },
          {
            name: "week",
          },
        ],
        orderBys: [
          {
            dimension: {
              dimensionName: "date",
              orderType: "ALPHANUMERIC",
            },
          },
        ],
      },
    };

    const url = process.env.REACT_APP_URL_BACKEND + "progettos/" + id;
    await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setDateStart(startDate);
        setDateEnd(endDate);
        setData(data.project?.rows || []);
      })
      .catch((error) => {
        setModalError(true);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  // useEffect(() => {
  //   const today = new Date();
  //   const lastWeek = addDays(today, -30);
  //   const yesterday = addDays(today, -1);
  //   const dateStart = format(lastWeek, "yyyy-MM-dd");
  //   const dateEnd = format(yesterday, "yyyy-MM-dd");
  //   setDateStart(dateStart);
  //   setDateEnd(dateEnd);
  //   fetchAnalyticsProjects(dateStart, dateEnd);
  //   fetchTable(
  //     dateStart,
  //     dateEnd,
  //     null,
  //     null,
  //     null,
  //     "sessionDefaultChannelGroup"
  //   );
  // }, [router.project]);

  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    const propID = router.project;
    const properties = router.properties;
    if (!containsOnlyNumbers(propID) || !containsOnlyNumbers(properties)) {
      console.error("Invalid project ID");
      return navigate("/");
    }

    const urlParams = new URLSearchParams(location.search);

    // Ottieni le date dai parametri di query, se disponibili
    const paramDataStart = convertDateFormat(urlParams.get("dateStart"));
    const paramDataEnd = convertDateFormat(urlParams.get("dateEnd"));

    const paramDataStartCompare = convertDateFormat(
      urlParams.get("dateStartCompare")
    );
    const paramDataEndCompare = convertDateFormat(
      urlParams.get("dateEndCompare")
    );
    // Imposta le date di default se i parametri non sono presenti
    const today = new Date();
    const lastWeek = addDays(today, -30);
    const yesterday = addDays(today, -1);

    // Usa le date dai parametri se disponibili, altrimenti usa le date di default
    const start = paramDataStart || format(lastWeek, "yyyy-MM-dd");
    const end = paramDataEnd || format(yesterday, "yyyy-MM-dd");


    setTypeSelected(determineIntervalType(start, end));

    setDateStart(start);
    setDateEnd(end);
    setDateStartCompare(paramDataStartCompare);
    setDateEndCompare(paramDataEndCompare);
    setIsComparing(paramDataStartCompare && paramDataEndCompare);
    fetchAnalyticsProjects(
      start,
      end,
      paramDataStartCompare,
      paramDataEndCompare,
      paramDataStartCompare && paramDataEndCompare
    );
    fetchTable(
      start,
      end,
      paramDataStartCompare,
      paramDataEndCompare,
      paramDataStartCompare && paramDataEndCompare,
      "sessionDefaultChannelGroup"
    );
  }, [router]);

  const convertDateFormat = (dateString) => {
    if (!containsOnlyNumbers(dateString)) {
      return null;
    }
    if (dateString?.length !== 8) {
      return null;
    }

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1; // Mese basato su 0
    const day = dateString.substring(6, 8);

    // Crea una nuova data in formato UTC
    const date = new Date(Date.UTC(year, month, day));

    // Converti la data in formato yyyy-MM-dd
    const formattedDate = date.toISOString().split("T")[0];

    return formattedDate;
  };

  const [pos, setPos] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const positions = cookies.get("positions");
    const initialData_ = positions || initialData;
    setPos(initialData_);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const itemButton = [
    // {
    //   id: 1,
    //   name: "Hourly",
    //   value: "hourly",
    // },
    {
      id: 2,
      name: "Daily",
      value: "daily",
    },
    {
      id: 3,
      name: "Monthly",
      value: "monthly",
    },
    {
      id: 4,
      name: "Weekly",
      value: "weekly",
    },
  ];

  const [buttonSelected, setButtonSelected] = useState("daily");

  const [typeSelected, setTypeSelected] = useState("last30Days");
  const [typeSelectedCompare, setTypeSelectedCompare] =
    useState("previousPeriod");

  const [labelMain, setLabelMain] = useState("Users");
  const [labelSecond, setLabelSecond] = useState("New Users");

  const [, setProjects] = useRecoilState(projectsState);

  const [modalError, setModalError] = useState(false);

  const logout = async () => {
    try {
      const token = cookies.get("jwt");
      const user_ = cookies.get("user");
      if (user_ && user_.id) {
        const response = await fetch(
          process.env.REACT_APP_URL_BACKEND + "users/" + user_.id,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {},
          }
        );
        await response.json();
      }
      cookies.remove("user");
      cookies.remove("jwt");
      cookies.remove("color");
      cookies.remove("positions");
      cookies.remove("Wizard");
      cookies.remove("Wizard_welcome");
      LogoutCache(setProjects, cookies);
      navigate("/login");
    } catch (error) {
      cookies.remove("user");
      cookies.remove("jwt");
      cookies.remove("color");
      cookies.remove("positions");
      cookies.remove("Wizard");
      cookies.remove("Wizard_welcome");
      LogoutCache(setProjects, cookies);
      navigate("/login");
    }
  };

  const [selectedData, setSelectedData] = useState([]);

  const handleCategoryClick = (category) => {
    pushEventsToDataLayer({
      event: "element_click",
      "data-click-text": category.name?.toLowerCase()?.replace(/[\s\/]/g, "-"),
    });
    // if (dataTabella[categoryMain].find((a) => a?.nameReal === category).item.length === 0) {
    setSelectedPrimaryDimension(category.type);
    fetchTable(
      dateStart,
      dateEnd,
      dateStartCompare,
      dateEndCompare,
      isComparing,
      category.type
    );
    // } else {
    //   const cat = dataTabella[categoryMain].find((a) => a?.nameReal === category)
    //   setSelectedCategory(cat?.name);
    //   setSelectedData(cat.item);
    // }
  };

  const fetchTable = async (
    start,
    end,
    startCompare,
    endCompare,
    compare,
    dimension
  ) => {
    const id = router.project;
    if (!containsOnlyNumbers(id)) {
      navigate("/");
      return;
    }
    setLoaderTable(true);
    setModalError(false);
    const token = cookies.get("jwt");
    if (!token) {
      navigate("/login");
      return;
    }
    const startDate = start;
    const endDate = end;

    // check dateStart and dateEnd is date format
    if (!startDate || !endDate) {
      setLoaderTable(false);
      // todo
      return;
    }
    const dateRanges = [
      {
        startDate: startDate,
        endDate: endDate,
      },
    ];

    if (compare) {
      setIsComparing(true);
      const startDateCompare = startCompare;
      const endDateCompare = endCompare;
      if (isComparing && (!startDateCompare || !endDateCompare)) {
        setLoaderTable(false);
        // todo
        return;
      }

      dateRanges.push({
        startDate: startDateCompare,
        endDate: endDateCompare,
      });
    }

    const data = {
      query: {
        metrics: [
          { name: "sessions" },
          { name: "totalUsers" },
          { name: "newUsers" },
          { name: "bounceRate" },
          { name: "screenPageViewsPerSession" },
          { name: "averageSessionDuration" },
        ],
        dateRanges: dateRanges,
        dimensions: [
          {
            name: dimension,
          },
          // {
          //   name: "sourceMedium",
          // },
        ],
        orderBys: [
          {
            // dimension: {
            //   dimensionName: dimension,
            //   orderType: "ALPHANUMERIC",
            // },
            metric: {
              metricName: "totalUsers",
            },
            desc: true,
          },
        ],
      },
    };

    const url = process.env.REACT_APP_URL_BACKEND + "progettos/" + id;
    await fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        const rows = data.project?.rows || [];
        const sum = [
          { name: "Users" },
          { name: "New Users" },
          { name: "Sessions" },
          { name: "Bounce Rate" },
          { name: "Pages/Session" },
          { name: "Avg. Session Duration" },
        ];
        if (rows.length > 0) {
          if (compare) {
            const sumSessionCompare = rows
              .filter((row) => row.dimensionValues[1]?.value === "date_range_1")
              .reduce((acc, row) => {
                return acc + parseIntOrFloat(row.metricValues[0]?.value);
              }, 0);
            const sumTotalUserCompare = rows
              .filter((row) => row.dimensionValues[1]?.value === "date_range_1")
              .reduce((acc, row) => {
                return acc + parseIntOrFloat(row.metricValues[1]?.value);
              }, 0);
            const sumNewUserCompare = rows
              .filter((row) => row.dimensionValues[1]?.value === "date_range_1")
              .reduce((acc, row) => {
                return acc + parseIntOrFloat(row.metricValues[2]?.value);
              }, 0);

            const sumBounceRateCompare = rows
              .filter((row) => row.dimensionValues[1]?.value === "date_range_1")
              .reduce((acc, row) => {
                return acc + parseIntOrFloat(row.metricValues[3]?.value);
              }, 0);

            const avgBoounceRate =
              sumBounceRateCompare /
              rows.filter(
                (row) => row.dimensionValues[1]?.value === "date_range_1"
              )?.length;

            const sumPagesPerSessionCompare = rows
              .filter((row) => row.dimensionValues[1]?.value === "date_range_1")
              .reduce((acc, row) => {
                return acc + parseIntOrFloat(row.metricValues[4]?.value);
              }, 0);

            const avgPagesPerSession =
              sumPagesPerSessionCompare /
              rows.filter(
                (row) => row.dimensionValues[1]?.value === "date_range_1"
              )?.length;
            const sumAvgSessionDurationCompare = rows
              .filter((row) => row.dimensionValues[1]?.value === "date_range_1")
              .reduce((acc, row) => {
                return acc + parseIntOrFloat(row.metricValues[5]?.value);
              }, 0);
            const avgSessionDuration =
              sumAvgSessionDurationCompare /
              rows.filter(
                (row) => row.dimensionValues[1]?.value === "date_range_1"
              )?.length;

            sum[0].sumCompare = sumTotalUserCompare;
            sum[2].sumCompare = sumSessionCompare;
            sum[1].sumCompare = sumNewUserCompare;
            sum[3].sumCompare = avgBoounceRate;
            sum[4].sumCompare = avgPagesPerSession;
            sum[5].sumCompare = avgSessionDuration;
          }

          const sumSession = rows
            .filter(
              (row) =>
                row.dimensionValues.length === 1 ||
                row.dimensionValues[1]?.value === "date_range_0"
            )
            .reduce((acc, row) => {
              return acc + parseIntOrFloat(row.metricValues[0]?.value);
            }, 0);

          const sumTotalUser = rows
            .filter(
              (row) =>
                row.dimensionValues.length === 1 ||
                row.dimensionValues[1]?.value === "date_range_0"
            )
            .reduce((acc, row) => {
              return acc + parseIntOrFloat(row.metricValues[1]?.value);
            }, 0);

          const sumNewUser = rows
            .filter(
              (row) =>
                row.dimensionValues.length === 1 ||
                row.dimensionValues[1]?.value === "date_range_0"
            )
            .reduce((acc, row) => {
              return acc + parseIntOrFloat(row.metricValues[2]?.value);
            }, 0);
          const sumBounceRate = rows
            .filter(
              (row) =>
                row.dimensionValues.length === 1 ||
                row.dimensionValues[1]?.value === "date_range_0"
            )
            .reduce((acc, row) => {
              return acc + parseIntOrFloat(row.metricValues[3]?.value);
            }, 0);

          const avgBoounceRate =
            sumBounceRate /
            rows.filter(
              (row) =>
                row.dimensionValues.length === 1 ||
                row.dimensionValues[1]?.value === "date_range_0"
            )?.length;
          const sumPagesPerSession = rows
            .filter(
              (row) =>
                row.dimensionValues.length === 1 ||
                row.dimensionValues[1]?.value === "date_range_0"
            )
            .reduce((acc, row) => {
              return acc + parseIntOrFloat(row.metricValues[4]?.value);
            }, 0);
          const sumAvgSessionDuration = rows
            .filter(
              (row) =>
                row.dimensionValues.length === 1 ||
                row.dimensionValues[1]?.value === "date_range_0"
            )
            .reduce((acc, row) => {
              return acc + parseIntOrFloat(row.metricValues[5]?.value);
            }, 0);
          const avgPagesPerSession =
            sumPagesPerSession /
            rows.filter(
              (row) =>
                row.dimensionValues.length === 1 ||
                row.dimensionValues[1]?.value === "date_range_0"
            )?.length;
          const avgSessionDuration =
            sumAvgSessionDuration /
            rows.filter(
              (row) =>
                row.dimensionValues.length === 1 ||
                row.dimensionValues[1]?.value === "date_range_0"
            )?.length;
          sum[0].sum = sumTotalUser;
          sum[2].sum = sumSession;
          sum[1].sum = sumNewUser;
          sum[3].sum = avgBoounceRate;
          sum[4].sum = avgPagesPerSession;
          sum[5].sum = avgSessionDuration;
          // sort value desc

          setTotalSum(sum);

          const dataTabella_ = rows.map((row) => {
            return {
              name: row.dimensionValues[0]?.value,
              compare:
                row.dimensionValues.length > 1
                  ? row.dimensionValues[1]?.value
                  : "date_range_0",
              date:
                row.dimensionValues.length > 1 &&
                row.dimensionValues[1]?.value === "date_range_1"
                  ? format(new Date(dateRanges[1].startDate), "dd MMM yyyy") +
                    " - " +
                    format(new Date(dateRanges[1].endDate), "dd MMM yyyy")
                  : format(new Date(dateRanges[0].startDate), "dd MMM yyyy") +
                    " - " +
                    format(new Date(dateRanges[0].endDate), "dd MMM yyyy"),
              // dateRanges[1].startDate + " / " + dateRanges[1].endDate : dateRanges[0].startDate + " / " + dateRanges[0].endDate,
              sessions: row.metricValues[0]?.value,
              totalUsers: row.metricValues[1]?.value,
              newUsers: row.metricValues[2]?.value,
              bounceRate: row.metricValues[3]?.value,
              pagesPerSession: row.metricValues[4]?.value,
              avgSessionDuration: row.metricValues[5]?.value,
            };
          });

          const groupedData = dataTabella_.reduce((acc, item) => {
            if (!acc[item.name]) {
              acc[item.name] = [];
            }
            acc[item.name].push(item);
            return acc;
          }, {});

          for (const key in groupedData) {
            groupedData[key].sort((a, b) => {
              if (
                a.compare === "date_range_0" &&
                b.compare !== "date_range_0"
              ) {
                return -1;
              }
              if (
                a.compare !== "date_range_0" &&
                b.compare === "date_range_0"
              ) {
                return 1;
              }
              if (
                a.compare === "date_range_1" &&
                b.compare !== "date_range_1"
              ) {
                return -1;
              }
              if (
                a.compare !== "date_range_1" &&
                b.compare === "date_range_1"
              ) {
                return 1;
              }
              return 0;
            });
          }

          setSelectedData(groupedData);
        } else {
          setSelectedData([]);
          setTotalSum(sum);
        }
      })
      .catch((error) => {
        setModalError(true);
      })
      .finally(() => {
        setLoaderTable(false);
      });
  };

  function formatIfDecimal(num) {
    let num_;
    // Controlla se il numero ha una parte decimale
    if (num % 1 !== 0) {
      num = parseFloat(num);
      num_ = num.toFixed(2).toLocaleString("en-US");
    } else {
      num = parseFloat(num);
      num_ = num.toLocaleString("en-US");
    }
    // // check not is Nan
    // if (isNaN(num_) || num_ === "Infinity" || num_ === "-Infinity") {
    //   return "-"
    // }
    return num_;
  }

  const [totalSum, setTotalSum] = useState([]);

  const primaryDimension = [
    {
      name: "Default Channel Group",
      type: "sessionDefaultChannelGroup",
    },
    {
      name: "Source/Medium",
      type: "sessionSourceMedium",
    },
    {
      name: "Source",
      type: "sessionSource",
    },
    {
      name: "Medium",
      type: "sessionMedium",
    },
  ];

  const [selectedPrimaryDimension, setSelectedPrimaryDimension] = useState(
    "sessionDefaultChannelGroup"
  );

  const parseIntOrFloat = (num) => {
    if (num % 1 !== 0) {
      return parseFloat(num);
    } else {
      return parseFloat(num);
    }
  };
  function formatDuration(seconds) {
    if (!seconds || isNaN(seconds)) return "00:00:00";

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    // Utilizziamo date-fns per formattare il tempo
    const formatted = format(
      new Date(0, 0, 0, hours, minutes, secs),
      "HH:mm:ss"
    );

    return formatted;
  }

  return (
    <div className="">
      <div className="p-20">
        {loader ? (
          <div className="h-[413px] bg-white flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="flex justify-end items-center ">
              <div className="w-fit">
                <ComparedatePicker
                  dateStart={dateStart}
                  dateEnd={dateEnd}
                  setTypeSelected={setTypeSelected}
                  isComparing={isComparing}
                  dateStartCompare={dateStartCompare}
                  dateEndCompare={dateEndCompare}
                  typeSelected={typeSelected}
                  setTypeSelectedCompare={setTypeSelectedCompare}
                  typeSelectedCompare={typeSelectedCompare}
                />
              </div>
            </div>
            {data?.length === 0 ? (
              <h1 className="text-center text-2xl my-20">
                No data for this period
              </h1>
            ) : (
              <>
                {data?.length > 0 && (
                  <div className="flex justify-between items-center my-4">
                    <div className="flex gap-4">
                      <select
                        className="border border-gray-300 rounded-md p-2 max-w-[160px]"
                        value={dataLine}
                        onClick={() => {
                          pushEventsToDataLayer({
                            event: "first_dimension_click",
                          });
                        }}
                        onChange={(e) => {
                          setDataLine(e.target.value);
                          setLabelMain(
                            allMetric.find(
                              (metric) => metric.id === parseFloat(e.target.value)
                            )?.name
                          );
                          if (dataLineSecond === parseFloat(e.target.value)) {
                            setDataLineSecond(
                              secondMetric.find(
                                (metric) =>
                                  metric.id !== parseFloat(e.target.value)
                              ).id
                            );
                          }
                        }}
                      >
                        {allMetric
                          .filter(
                            (metric) => metric.id !== parseFloat(dataLineSecond)
                          )
                          .map((metric, index) => {
                            return (
                              <option key={index} value={metric.id}>
                                {metric?.name}
                              </option>
                            );
                          })}
                      </select>
                      <select
                        className="border border-gray-300 rounded-md p-2 max-w-[160px]"
                        value={dataLineSecond}
                        onClick={() => {
                          pushEventsToDataLayer({
                            event: "second_dimension_click",
                          });
                        }}
                        onChange={(e) => {
                          setDataLineSecond(e.target.value);
                          setLabelSecond(
                            secondMetric.find(
                              (metric) => metric.id === parseFloat(e.target.value)
                            )?.name
                          );
                        }}
                      >
                        {secondMetric
                          .filter((metric) => metric.id !== parseFloat(dataLine))
                          .map((metric, index) => {
                            return (
                              <option key={index} value={metric.id}>
                                {metric?.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="flex">
                      {itemButton.map((item, index) => {
                        return (
                          <button
                            key={index}
                            className={`mx-2
                         rounded-md px-2 py-1 text-sm
                         text-gray-700 border border-gray-300
                      ${
                        buttonSelected === item.value
                          ? "bg-gray-300"
                          : "bg-white hover:bg-gray-100"
                      } 
                          `}
                            onClick={() => {
                              setButtonSelected(item.value);
                              pushEventsToDataLayer({
                                event: "button_click",
                                "data-click-text": item.value,
                              });
                            }}
                          >
                            {item?.name}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}
                {data?.length > 0 && (
                  <div className="flex gap-[12px] flex-wrap  items-end my-4 mb-[36px]">
                    {pos.map((item, index) => (
                      <div className="w-fit max-w-full ">
                        {item.type === "main" && (
                          <div style={{ width: windowWidth - 10 * 16 }}>
                            <AnalyticsChart
                              rows={data}
                              position={dataLine}
                              buttonSelected={buttonSelected}
                              color={color}
                              secondPosition={dataLineSecond}
                              labelMain={labelMain}
                              labelSecond={labelSecond}
                              dateStartCompare={dateStartCompare}
                              dateEndCompare={dateEndCompare}
                              isComparing={isComparing}
                              dateStart={dateStart}
                              dateEnd={dateEnd}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        )}
        {loaderTablle ? (
          <div className="h-[400px] bg-white flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className=" text-sm">
              <div className="flex text-sm items-center gap-2">
                <h2 className="">Primary Dimension:</h2>
                {primaryDimension.map((item, index) => (
                  <div
                    key={item.type}
                    className={
                      selectedPrimaryDimension === item.type
                        ? " text-black font-bold"
                        : " text-[#0F91C9] cursor-pointer"
                    }
                    onClick={() =>
                      selectedPrimaryDimension === item.type
                        ? null
                        : handleCategoryClick(item)
                    }
                  >
                    {item?.name}
                  </div>
                ))}
              </div>
              <div className="w-full p-4">
                <table className="min-w-full  ">
                  <thead className=" bg-gray-200">
                    <tr className="text-left">
                      <th className="border-t border-l  border-r  border-gray-300"></th>
                      <th className="border-t border-l border-b border-gray-300 pl-1 py-2">
                        Acquisition
                      </th>
                      <th className="border-t border-b border-gray-300"></th>
                      <th className="border-t border-r border-b border-gray-300"></th>
                      <th className="border-t border-l border-b border-gray-300 pl-1 py-2">
                        Behaviour
                      </th>
                      <th className="border-t border-b border-gray-300"></th>
                      <th className="border-t border-r border-b border-gray-300"></th>
                    </tr>
                    <tr className="bg-gray-200 border-b border-gray-400">
                      <th className=" border-r border-b border-l  border-gray-300 p-2 table-cell align-baseline ">
                        {
                          primaryDimension.find(
                            (item) => item.type === selectedPrimaryDimension
                          )?.name
                        }
                      </th>
                      {totalSum.map((sum, index) => (
                        <th
                          key={index}
                          className="  border-r border-b  border-gray-300"
                        >
                          <div className="py-2 border-b  border-gray-300 font-bold">
                            {sum?.name}
                          </div>
                          <div className="p-2 bg-gray-100">
                            {sum.sumCompare &&
                              (sum?.name === "Bounce Rate" ? (
                                <span className="flex items-center justify-end   text-base">
                                  {sum.sum - sum.sumCompare > 0 ? "+" : ""}
                                  {formatIfDecimal(
                                    ((sum.sum - sum.sumCompare) * 100).toFixed(
                                      2
                                    )
                                  )}
                                  %
                                  {sum.sum - sum.sumCompare > 0 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon icon-tabler icon-tabler-arrow-big-up-filled"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="#233a3a"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path
                                        d="M10.586 3l-6.586 6.586a2 2 0 0 0 -.434 2.18l.068 .145a2 2 0 0 0 1.78 1.089h2.586v7a2 2 0 0 0 2 2h4l.15 -.005a2 2 0 0 0 1.85 -1.995l-.001 -7h2.587a2 2 0 0 0 1.414 -3.414l-6.586 -6.586a2 2 0 0 0 -2.828 0z"
                                        strokeWidth="0"
                                        fill="#02bd02"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon icon-tabler icon-tabler-arrow-big-down-filled"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="#233a3a"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path
                                        d="M10 2l-.15 .005a2 2 0 0 0 -1.85 1.995v6.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-6.999a2 2 0 0 0 -2 -2h-4z"
                                        strokeWidth="0"
                                        fill="#FF0000"
                                      />
                                    </svg>
                                  )}
                                </span>
                              ) : (
                                <span className="flex items-center justify-end    text-base">
                                  {sum.sum - sum.sumCompare > 0 ? "+" : ""}
                                  {formatIfDecimal(
                                    (
                                      ((sum.sum - sum.sumCompare) /
                                        sum.sumCompare) *
                                      100
                                    ).toFixed(2)
                                  )}
                                  %
                                  {sum.sum - sum.sumCompare > 0 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon icon-tabler icon-tabler-arrow-big-up-filled"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="#233a3a"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path
                                        d="M10.586 3l-6.586 6.586a2 2 0 0 0 -.434 2.18l.068 .145a2 2 0 0 0 1.78 1.089h2.586v7a2 2 0 0 0 2 2h4l.15 -.005a2 2 0 0 0 1.85 -1.995l-.001 -7h2.587a2 2 0 0 0 1.414 -3.414l-6.586 -6.586a2 2 0 0 0 -2.828 0z"
                                        strokeWidth="0"
                                        fill="#02bd02"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon icon-tabler icon-tabler-arrow-big-down-filled"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="#233a3a"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path
                                        d="M10 2l-.15 .005a2 2 0 0 0 -1.85 1.995v6.999l-2.586 .001a2 2 0 0 0 -1.414 3.414l6.586 6.586a2 2 0 0 0 2.828 0l6.586 -6.586a2 2 0 0 0 .434 -2.18l-.068 -.145a2 2 0 0 0 -1.78 -1.089l-2.586 -.001v-6.999a2 2 0 0 0 -2 -2h-4z"
                                        strokeWidth="0"
                                        fill="#FF0000"
                                      />
                                    </svg>
                                  )}
                                </span>
                              ))}
                            <span className="flex items-center justify-end  text-base">
                              <span className="flex items-center justify-end font-normal">
                                {sum?.name === "Bounce Rate"
                                  ? formatIfDecimal(
                                      (sum.sum * 100).toFixed(2)
                                    ) + "%"
                                  : sum?.name === "Avg. Session Duration"
                                  ? formatDuration(sum.sum)
                                  : formatIfDecimal(sum.sum)}
                              </span>
                              <span className="flex items-center justify-end  bg-gray-100 text-base font-normal">
                                {sum.sumCompare && (
                                  <>
                                    <span className="mx-2">vs</span>
                                    {sum?.name === "Bounce Rate"
                                      ? formatIfDecimal(
                                          (sum.sumCompare * 100).toFixed(2)
                                        ) + "%"
                                      : sum?.name === "Avg. Session Duration"
                                      ? formatDuration(sum.sumCompare)
                                      : formatIfDecimal(sum.sumCompare)}
                                  </>
                                )}
                              </span>
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {Object.keys(selectedData)?.length > 0 ? (
                    <tbody className="">
                      {Object.keys(selectedData)
                        .slice(0, 10)
                        .map((name, index) => (
                          <React.Fragment key={index}>
                            {selectedData[name].length > 1 ? (
                              <>
                                <tr className="border border-gray-200 p-2 text-left tooltipo">
                                  <th
                                    className="border border-gray-200 p-2 text-left text-[#0F91C9]  "
                                    onClick={() => {
                                      pushEventsToDataLayer({
                                        event: "element_click",
                                        "data-click-text": name
                                          ?.toLowerCase()
                                          ?.replace(/[\s\/]/g, "-"),
                                      });
                                    }}
                                  >
                                    {name}
                                    <a
                                    onClick={() => {
                                      pushEventsToDataLayer({
                                        event: "request_information",
                                        "data-click-text": name
                                          ?.toLowerCase()
                                          ?.replace(/[\s\/]/g, "-"),
                                      });
                                    }}
                                    className="tooltiptexto hover:underline"
                                    href="/form"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Click functionality coming soon
                                  </a>
                                  </th>
                                </tr>
                                {selectedData[name].map((group, groupIndex) => (
                                  <tr key={groupIndex}>
                                    <th className="border border-gray-200 p-2 text-left font-normal">
                                      {group.date}
                                    </th>
                                    <th className="border border-gray-200 p-2 text-right bg-gray-100 font-normal">
                                      <span>
                                        {formatIfDecimal(group.totalUsers)}
                                      </span>
                                      <span className="text-sm text-gray-400 ml-1">
                                        (
                                        {formatIfDecimal(
                                          (
                                            (group.totalUsers /
                                              totalSum[0].sum) *
                                            100
                                          ).toFixed(2)
                                        )}
                                        %)
                                      </span>
                                    </th>
                                    <th className="border border-gray-200 p-2 text-right font-normal">
                                      <span>
                                        {formatIfDecimal(group.newUsers)}
                                      </span>
                                      <span className="text-sm text-gray-400 ml-1">
                                        (
                                        {formatIfDecimal(
                                          (
                                            (group.newUsers / totalSum[1].sum) *
                                            100
                                          ).toFixed(2)
                                        )}
                                        %)
                                      </span>
                                    </th>
                                    <th className="border border-gray-200 p-2 text-right font-normal">
                                      <span>
                                        {formatIfDecimal(group.sessions)}
                                      </span>
                                      <span className="text-sm text-gray-400 ml-1">
                                        (
                                        {formatIfDecimal(
                                          (
                                            (group.sessions / totalSum[2].sum) *
                                            100
                                          ).toFixed(2)
                                        )}
                                        %)
                                      </span>
                                    </th>
                                    <th className="border border-gray-200 p-2 text-right font-normal">
                                      {formatIfDecimal(group.bounceRate)} %
                                    </th>
                                    <th className="border border-gray-200 p-2 text-right font-normal">
                                      {formatIfDecimal(group.pagesPerSession)}
                                    </th>
                                    <th className="border border-gray-200 p-2 text-right font-normal">
                                      {formatDuration(group.avgSessionDuration)}
                                    </th>
                                  </tr>
                                ))}
                                <tr>
                                  <th className="border border-gray-200 p-2 text-left text-sm">
                                    % Change
                                  </th>
                                  <th
                                    className={`border border-gray-200 p-2 text-right bg-gray-100 
                                            ${
                                              selectedData[name][0].totalUsers -
                                                selectedData[name][1]
                                                  .totalUsers >
                                              0
                                                ? `text-green-500`
                                                : `text-red-500`
                                            }`}
                                  >
                                    {formatIfDecimal(
                                      ((selectedData[name][0].totalUsers -
                                        selectedData[name][1].totalUsers) /
                                        selectedData[name][1].totalUsers) *
                                        100
                                    )}{" "}
                                    %
                                  </th>
                                  <th
                                    className={`border border-gray-200 p-2 text-right bg-gray-100 
                                            ${
                                              selectedData[name][0].newUsers -
                                                selectedData[name][1].newUsers >
                                              0
                                                ? `text-green-500`
                                                : `text-red-500`
                                            }`}
                                  >
                                    {formatIfDecimal(
                                      ((selectedData[name][0].newUsers -
                                        selectedData[name][1].newUsers) /
                                        selectedData[name][1].newUsers) *
                                        100
                                    )}{" "}
                                    %
                                  </th>
                                  <th
                                    className={`border border-gray-200 p-2 text-right bg-gray-100 
                                            ${
                                              selectedData[name][0].sessions -
                                                selectedData[name][1].sessions >
                                              0
                                                ? `text-green-500`
                                                : `text-red-500`
                                            }`}
                                  >
                                    {}
                                    {formatIfDecimal(
                                      ((selectedData[name][0].sessions -
                                        selectedData[name][1].sessions) /
                                        selectedData[name][1].sessions) *
                                        100
                                    )}{" "}
                                    %
                                  </th>
                                  <th
                                    className={`border border-gray-200 p-2 text-right bg-gray-100 
                                            ${
                                              selectedData[name][0].bounceRate -
                                                selectedData[name][1]
                                                  .bounceRate >
                                              0
                                                ? `text-green-500`
                                                : `text-red-500`
                                            }`}
                                  >
                                    {formatIfDecimal(
                                      ((selectedData[name][0].bounceRate -
                                        selectedData[name][1].bounceRate) /
                                        selectedData[name][1].bounceRate) *
                                        100
                                    )}{" "}
                                    %
                                  </th>
                                  <th
                                    className={`border border-gray-200 p-2 text-right bg-gray-100 
                                            ${
                                              selectedData[name][0]
                                                .pagesPerSession -
                                                selectedData[name][1]
                                                  .pagesPerSession >
                                              0
                                                ? `text-green-500`
                                                : `text-red-500`
                                            }`}
                                  >
                                    {formatIfDecimal(
                                      ((selectedData[name][0].pagesPerSession -
                                        selectedData[name][1].pagesPerSession) /
                                        selectedData[name][1].pagesPerSession) *
                                        100
                                    )}{" "}
                                    %
                                  </th>
                                  <th
                                    className={`border border-gray-200 p-2 text-right bg-gray-100 
                                            ${
                                              selectedData[name][0]
                                                .avgSessionDuration -
                                                selectedData[name][1]
                                                  .avgSessionDuration >
                                              0
                                                ? `text-green-500`
                                                : `text-red-500`
                                            }`}
                                  >
                                    {formatIfDecimal(
                                      ((selectedData[name][0]
                                        .avgSessionDuration -
                                        selectedData[name][1]
                                          .avgSessionDuration) /
                                        selectedData[name][1]
                                          .avgSessionDuration) *
                                        100
                                    )}{" "}
                                    %
                                  </th>
                                </tr>
                              </>
                            ) : (
                              selectedData[name].map((group, groupIndex) => (
                                <tr key={groupIndex} className="tooltipo">
                                  <th
                                    className="border border-gray-200 p-2 text-left text-[#0F91C9] "
                                    onClick={() => {
                                      pushEventsToDataLayer({
                                        event: "element_click",
                                        "data-click-text": name
                                          ?.toLowerCase()
                                          ?.replace(/[\s\/]/g, "-"),
                                      });
                                    }}
                                  >
                                    {name}
                                    <a
                                    onClick={() => {
                                      pushEventsToDataLayer({
                                        event: "request_information",
                                        "data-click-text": name
                                          ?.toLowerCase()
                                          ?.replace(/[\s\/]/g, "-"),
                                      });
                                    }}
                                    className="tooltiptexto hover:underline"
                                    href="/form"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Click functionality coming soon
                                  </a>
                                  </th>
                                  <th className="border border-gray-200 p-2 text-right bg-gray-100 font-normal">
                                    <span>
                                      {formatIfDecimal(group.totalUsers)}
                                    </span>
                                    <span className="text-sm text-gray-400 ml-1">
                                      (
                                      {formatIfDecimal(
                                        (
                                          (group.totalUsers / totalSum[0].sum) *
                                          100
                                        ).toFixed(2)
                                      )}
                                      %)
                                    </span>
                                  </th>
                                  <th className="border border-gray-200 p-2 text-right font-normal">
                                    <span>
                                      {formatIfDecimal(group.newUsers)}
                                    </span>
                                    <span className="text-sm text-gray-400 ml-1">
                                      (
                                      {formatIfDecimal(
                                        (
                                          (group.newUsers / totalSum[1].sum) *
                                          100
                                        ).toFixed(2)
                                      )}
                                      %)
                                    </span>
                                  </th>
                                  <th className="border border-gray-200 p-2 text-right font-normal">
                                    <span>
                                      {formatIfDecimal(group.sessions)}
                                    </span>
                                    <span className="text-sm text-gray-400 ml-1">
                                      (
                                      {formatIfDecimal(
                                        (
                                          (group.sessions / totalSum[2].sum) *
                                          100
                                        ).toFixed(2)
                                      )}
                                      %)
                                    </span>
                                  </th>
                                  <th className="border border-gray-200 p-2 text-right font-normal">
                                    {formatIfDecimal(group.bounceRate)} %
                                  </th>
                                  <th className="border border-gray-200 p-2 text-right font-normal">
                                    {formatIfDecimal(group.pagesPerSession)}
                                  </th>
                                  <th className="border border-gray-200 p-2 text-right font-normal">
                                    {formatDuration(group.avgSessionDuration)}
                                  </th>
                                </tr>
                              ))
                            )}
                          </React.Fragment>
                        ))}
                    </tbody>
                  ) : (
                    <tbody className="pt-4">
                      <tr className="mt-4 pt-4">
                        <td colSpan="7" className="text-center mt-2">
                          There is no data for this view.
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </>
        )}
      </div>
      {modalError && (
        <Modal
          modalIsOpen={modalError}
          closeModal={() => setModalError(false)}
          message="Non è stato possibile recuperare i dati"
          logout={true}
          logoutFunction={logout}
        />
      )}
    </div>
  );
};

export default GoogleAnalyticsProjects;
