import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns"; // to handle time scales
import { getISOWeek, format, addDays } from "date-fns"; // import getISOWeek for week calculations

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

const AnalyticsChart = ({
  rows,
  position,
  buttonSelected,
  color,
  labelMain,
  dateStartCompare,
  dateEndCompare,
  isComparing,
  dateStart,
  dateEnd,
  secondPosition,
  labelSecond,
}) => {
  const [granularity, setGranularity] = useState(buttonSelected);
  // Sample data from the provided output

  const getLabelsAndData = (rows, granularity, position_) => {
    switch (granularity) {
      case "hourly":
        const hourlyData = rows
          .reduce((acc, row) => {
            const dateStr = row.dimensionValues[0]?.value || "";
            const hourStr = row.dimensionValues[1]?.value || "00";

            // Controlla se il formato di data e ora è corretto
            if (dateStr.length === 8 && hourStr.length <= 2) {
              const year = dateStr.substring(0, 4);
              const month = dateStr.substring(4, 6);
              const day = dateStr.substring(6, 8);
              const date = `${year}-${month}-${day}`;
              const hour = hourStr.padStart(2, "0");

              const dateTimeStr = `${date}T${hour}:00:00`;
              const value =
                parseFloat(row.metricValues[position_]?.value, 10) || 0;

              const existingIndex = acc.findIndex(
                (item) => item.dateTime === dateTimeStr
              );

              if (existingIndex >= 0) {
                acc[existingIndex].value += value;
              } else {
                acc.push({ dateTime: dateTimeStr, value });
              }
            } else {
              console.error("Invalid date or hour format:", dateStr, hourStr);
            }

            return acc;
          }, [])
          .map((item) => item.value);
        const labels = [
          ...new Set(
            rows.map((row) => {
              const dateStr = row.dimensionValues[0]?.value || "";
              const hourStr = row.dimensionValues[1]?.value || "00";

              if (dateStr.length === 8 && hourStr.length <= 2) {
                const year = dateStr.substring(0, 4);
                const month = dateStr.substring(4, 6);
                const day = dateStr.substring(6, 8);
                const hour = hourStr.padStart(2, "0");

                return `${year}-${month}-${day}T${hour}:00:00`;
              } else {
                console.error("Invalid date or hour format:", dateStr, hourStr);
                return null;
              }
            })
          ),
        ]
          .filter(Boolean)
          .sort();
        return {
          labels: labels,
          dataPoints: hourlyData,
        };
      case "daily":
        return {
          labels: [
            ...new Set(rows.map((row) => row.dimensionValues[0].value)),
          ].map(
            (dateStr) =>
              `${dateStr.substring(0, 4)}-${dateStr.substring(
                4,
                6
              )}-${dateStr.substring(6, 8)}`
          ),
          dataPoints: rows
            .reduce((acc, row) => {
              const dateStr = row.dimensionValues[0].value;
              const existingIndex = acc.findIndex(
                (item) => item.date === dateStr
              );
              const value = parseFloat(row.metricValues[position_]?.value, 10);
              if (existingIndex >= 0) {
                acc[existingIndex].value += value;
              } else {
                acc.push({ date: dateStr, value });
              }
              return acc;
            }, [])
            .map((item) => item.value),
        };
      case "weekly":
        return {
          labels: [
            ...new Set(
              rows.map((row) => {
                const dateStr = row.dimensionValues[0].value;
                const year = dateStr.substring(0, 4);
                const month = dateStr.substring(4, 6);
                const day = dateStr.substring(6, 8);
                const date = new Date(`${year}-${month}-${day}`);
                const week = getISOWeek(date);
                if (week < 10) {
                  return `${year}-W0${week}`;
                }
                return `${year}-W${week}`;
              })
            ),
          ],
          dataPoints: rows
            .reduce((acc, row) => {
              const dateStr = row.dimensionValues[0].value;
              const year = dateStr.substring(0, 4);
              const month = dateStr.substring(4, 6);
              const day = dateStr.substring(6, 8);
              const date = new Date(`${year}-${month}-${day}`);
              const week = getISOWeek(date);
              const weekStr = `${year}-W${week}`;
              const existingIndex = acc.findIndex(
                (item) => item.week === weekStr
              );
              const value = parseFloat(row.metricValues[position_]?.value, 10);
              if (existingIndex >= 0) {
                acc[existingIndex].value += value;
              } else {
                acc.push({ week: weekStr, value });
              }
              return acc;
            }, [])
            .map((item) => item.value),
        };
      case "monthly":
        return {
          labels: [
            ...new Set(
              rows.map((row) => row.dimensionValues[0].value.substring(0, 6))
            ),
          ].map(
            (dateStr) =>
              new Date(`${dateStr.substring(0, 4)}-${dateStr.substring(4, 6)}`)
          ),
          dataPoints: rows
            .reduce((acc, row) => {
              const monthStr = row.dimensionValues[0].value.substring(0, 6);
              const existingIndex = acc.findIndex(
                (item) => item.month === monthStr
              );
              const value = parseFloat(row.metricValues[position_]?.value, 10);
              if (existingIndex >= 0) {
                acc[existingIndex].value += value;
              } else {
                acc.push({ month: monthStr, value });
              }
              return acc;
            }, [])
            .map((item) => item.value),
        };
      default:
        return {
          labels: [],
          dataPoints: [],
        };
    }
  };

  const dataStart = new Date(dateStart);
  const dateStartFormat = format(dataStart, "yyyyMMdd");

  const dataEnd = new Date(dateEnd);
  const dateEndFormat = format(dataEnd, "yyyyMMdd");
  let rowsRange0 = rows.filter(
    (row) =>
      row.dimensionValues[4]?.value === "date_range_0" &&
      row.dimensionValues[0].value >= dateStartFormat &&
      row.dimensionValues[0].value <= dateEndFormat
  );
  let labelsRange0 = [];
  let dataPointsRange0 = [];

  if (rowsRange0.length > 0) {
    ({ labels: labelsRange0, dataPoints: dataPointsRange0 } = getLabelsAndData(
      rowsRange0,
      granularity,
      position
    ));
  } else {
    ({ labels: labelsRange0, dataPoints: dataPointsRange0 } = getLabelsAndData(
      rows,
      granularity,
      position
    ));
  }

  let datasets = [
    {
      label: labelMain,
      data: labelsRange0.map((label, index) => ({
        x: label,
        y: dataPointsRange0[index],
        z: label,
        l: labelMain,
      })),
      borderWidth: 4,
      borderColor: color[0],
      backgroundColor: color[0] + "33",
      fill: true,
      showLine: true,
      spanGaps: true,
    },
  ];

  if (secondPosition > -1) {
    let rowsRange0 = rows.filter(
      (row) =>
        row.dimensionValues[4]?.value === "date_range_0" &&
        row.dimensionValues[0].value >= dateStartFormat &&
        row.dimensionValues[0].value <= dateEndFormat
    );
    let labelsRange0 = [];
    let dataPointsRange0 = [];

    if (rowsRange0.length > 0) {
      ({ labels: labelsRange0, dataPoints: dataPointsRange0 } =
        getLabelsAndData(rowsRange0, granularity, secondPosition));
    } else {
      ({ labels: labelsRange0, dataPoints: dataPointsRange0 } =
        getLabelsAndData(rows, granularity, secondPosition));
    }

    datasets.push({
      label: labelSecond,
      data: labelsRange0.map((label, index) => ({
        x: label,
        y: dataPointsRange0[index],
        z: label,
        l: labelSecond,
      })),
      borderWidth: 4,
      borderColor: color[2],
      // backgroundColor: color[2] + "33",
      // fill: true,
      showLine: true,
      spanGaps: true,
    });
    datasets = datasets.reverse();
  }

  if (isComparing && dateStartCompare && dateEndCompare) {
    const dataStart = new Date(dateStartCompare);
    const dateStartFormat = format(dataStart, "yyyyMMdd");

    const dataEnd = new Date(dateEndCompare);
    const dateEndFormat = format(dataEnd, "yyyyMMdd");
    const rowsRange1 = rows.filter(
      (row) =>
        row.dimensionValues[4]?.value === "date_range_1" &&
        row.dimensionValues[0].value >= dateStartFormat &&
        row.dimensionValues[0].value <= dateEndFormat
    );
    let labels = [];
    let dataPoints = [];
    let labelsRange3 = [];
    let dataPointsRange3 = [];
    if (rowsRange1.length > 0) {
      ({ labels, dataPoints } = getLabelsAndData(
        rowsRange1,
        granularity,
        position
      ));
      ({ labels: labelsRange3, dataPoints: dataPointsRange3 } =
        getLabelsAndData(rowsRange1, granularity, secondPosition));
    } else {
    }
    let labels_ = [];
    // add days different to labelsRange0
    const daydifferece = labelsRange0.length - dataPoints.length;
    if (daydifferece < 0 && granularity !== "weekly") {
      // add days to labelsRange0
      const allDays = [];
      for (let i = 0; i < Math.abs(daydifferece); i++) {
        const day = format(
          addDays(labelsRange0[labelsRange0.length - 1], i + 1),
          "yyyy-MM-dd"
        );
        allDays.push(day);
        // labelsRange0.push(day);
        // dataPointsRange0.push(0);
      }
      labelsRange0 = [...labelsRange0, ...allDays];
      labels_ = labelsRange0;
    } else if (daydifferece > 0) {
      labels_ = labelsRange0;
    } else {
      labels_ = labelsRange0;
    }

    if (labels_.length > 0 && dataPoints.length > 0) {
      datasets.push({
        label: labelMain,
        data: labels_.map((label, index) => ({
          x: label,
          y: dataPoints[index],
          z: labels[index],
          l: labelMain,
        })),
        borderWidth: 4,
        borderColor: color[1],
        fill: false,
        showLine: true,
        spanGaps: true,
      });
      if (secondPosition > -1) {
        datasets.push({
          label: labelSecond,
          data: labels_.map((label, index) => ({
            x: label,
            y: dataPointsRange3[index],
            z: labelsRange3[index],
            l: labelSecond,
          })),
          borderWidth: 4,
          borderColor: color[3],
          fill: false,
          showLine: true,
          spanGaps: true,
        });
      }
    }
  }

  let radius = 3;

  if (dataPointsRange0.length > 100) {
    radius = 0;
  }

  const options = {
    // animation: {
    //   tension: {
    //     duration: 1000,
    //     easing: "linear",
    //     from: 1,
    //     to: 0,
    //     loop: false,
    //   },
    // },
    interaction: {
      intersect: false,
      axis: "x",
      //mode: 'nearest',
    },
    elements: {
      point: {
        radius: radius,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        scaleLineColor: "transparent",
        type: "time",
        time: {
          unit:
            granularity === "hourly"
              ? "day"
              : granularity === "daily"
              ? "day"
              : granularity === "weekly"
              ? "week"
              : granularity === "monthly"
              ? "month"
              : "year",
          tooltipFormat:
            granularity === "hourly"
              ? "yyyy-MM-dd HH:mm"
              : granularity === "daily"
              ? "yyyy-MM-dd"
              : granularity === "weekly"
              ? "yyyy-'W'II"
              : granularity === "monthly"
              ? "yyyy-MM"
              : "yyyy",
          displayFormats: {
            hour: "MMM dd",
            day: "MMM dd",
            week: "yyyy-'W'II",
            month: "MMM yyyy",
            year: "yyyy",
          },
        },
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: true,
          maxTicksLimit: 3,
        },
      },
    },
    ticks: {
      min: 0,
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: "top",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          padding: 10,
          // borderradius
          usePointStyle: true,
        },
        font: {
          size: 10,
          family: "Arial",
          weight: "bold",
          style: "normal",
          color: "black",
        },
      },
      grid: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const lab = context.raw.l;
            const val = context.raw.y;
            if (context.raw.z) {
              const day = context.raw.z;
              return `${lab}: ${val || ""} ${day}`;
            } else {
              return `${lab}: ${val || ""}`;
            }
          },
        },
      },
    },
  };
  if (datasets.length > 1) {
    // change position of object
    datasets = datasets.reverse();
  }
  const data = {
    labels: labelsRange0,
    datasets: datasets,
  };

  useEffect(() => {
    setGranularity(buttonSelected);
  }, [buttonSelected]);

  return <Line data={data} height={200} width={400} options={options} />;
};

export default AnalyticsChart;
