import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ButtonSidebar(props) {
  return (
    <NavLink to={"/" + props.path} 
    id={props.name}
    >
      <li
        className={`transition ease-in-out px-[13px] py-[13px] w-full rounded-[12px] mt-[12px] cursor-pointer ${
          props?.page === props?.selected && "bg-gray-300"
        } ${
          props?.sidebarOpen &&
          props?.page !== props?.selected &&
          "hover:bg-gray-100"
        } 
        `}
      >
        <span
          className={
            props?.sidebarOpen
              ? "w-full flex items-center "
              : "w-[20px] flex items-center"
          }
        >
          <LazyLoadImage
            src={props.imgNera}
            alt=""
            className={
              props?.page === props?.selected && props.page !== "profile"
                ? "w-[20px] h-[20px] invert-svg"
                : "w-[20px] h-[20px]"
            }
          />
          <span className="ml-[5px] w-full flex items-center justify-between">
            {props?.sidebarOpen && (
              <h4
                className={` ${
                  props?.page !== props?.selected
                    ? "text-primaryGray capitalize leading-[0px]"
                    : "text-gray capitalize leading-[0px]"
                }`}
              >
                {props?.name}
              </h4>
            )}
          </span>
        </span>
      </li>
    </NavLink>
  );
}
