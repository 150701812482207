const handleDateChange = (
  names,
  values,
  reset,
  navigate,
  location,
  queryString
) => {
  const queryParams = queryString.parse(location.search);

  // name and value is a array ["name0", "name1"] and ["value0", "value1"]
  if (names.length !== values.length) {
    console.error("Names and values must have the same length");
    return;
  }

  // Crea un nuovo oggetto di parametri di query
  const newParams = reset ? {} : { ...queryParams };

  // Aggiungi i nuovi parametri
  names.forEach((name, index) => {
    newParams[name] = convertToNumberString(values[index]);
  });
  const newSearchString = queryString.stringify(newParams);

  navigate({
    pathname: location.pathname,
    search: newSearchString,
  });
};

const convertToNumberString = (dateString) => {
  // Verifica se la data è nel formato corretto
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    return null;
  }

  // Dividi la stringa in componenti anno, mese e giorno
  const [year, month, day] = dateString.split("-");

  // Crea la stringa nel formato yyyymmdd
  const numberString = `${year}${month}${day}`;

  return numberString;
};
const createParamsSearch = (params) => {
  let search = "";
  for (const key in params) {
    search += key + "=" + params[key] + "&";
  }
  return search;
};

export { handleDateChange, createParamsSearch };
