import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/loader';

const Proprietas = ({ cookies }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, [])
  return (
    <div className='h-screen bg-white flex justify-center items-center p-20' >
      <Loader />
    </div >
  );
};

export default Proprietas;
