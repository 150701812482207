import React, { useEffect, useState } from "react";
import pushEventsToDataLayer from "../utils/gtm";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "../assets/YourVisualData/logo scritta nera.png";

const Form = ({ cookies }) => {
  useEffect(() => {
    const user = cookies.get("user");
    if (!user) {
      return navigate("/");
    }
    const email = user ? user.email : "";
    const fullName = user ? user.full_name : "";
    setFormData({ ...formData, email, fullName });
  }, []);
  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    mobile: "",
    company: "",
    requestType: "",
    description: "",
    recaptcha: "",
    privacyPolicy: false,
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [captcha] = useState(generateCaptcha());
  const [sendForm, setSendForm] = useState(false);
  const [message, setMessage] = useState("");

  function generateCaptcha() {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    return `${num1} + ${num2}`;
  }

  const validateForm = () => {
    let formErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex pattern

    if (!formData.email) formErrors.email = "Email is required";
    else if (!emailRegex.test(formData.email))
      formErrors.email = "Invalid email";

    if (!formData.fullName) formErrors.fullName = "Full name is required";
    if (!formData.description)
      formErrors.description = "Description is required";

    if (!formData.recaptcha || parseFloat(formData.recaptcha) !== eval(captcha)) {
      formErrors.recaptcha = "Invalid captcha";
    }

    if (!formData.privacyPolicy) {
      formErrors.privacyPolicy = "You must agree to the privacy policy";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setMessage("Missing required fields");
      return;
    }

    setDisabled(true);
    try {
      const emailSent = await sendEmail();
      if (emailSent) {
        setFormData({
          email: "",
          fullName: "",
          mobile: "",
          company: "",
          requestType: "",
          description: "",
          recaptcha: "",
          privacyPolicy: false,
        });
        setSendForm(true);
        countdown();
      } else {
        setMessage("Failed to send email, retry later");
      }
    } catch (error) {
      setMessage("An error occurred while sending email. Please try again.");
    } finally {
      setDisabled(false);
    }
  };

  const countdown = () => {
    let count = 5;
    const intervalId = setInterval(() => {
      if (count > 0) {
        setMessage("Redirecting in " +
          count.toString());
        count--;
      } else {
        clearInterval(intervalId);
        navigate("/");
      }
    }, 1000);
  };

  async function sendEmail() {
    const data = {
      email: formData.email,
      fullName: formData.fullName,
      mobile: formData.mobile,
      company: formData.company,
      requestType: formData.requestType,
      description: formData.description,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_URL_BACKEND + "forms",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            authorization: `Bearer ${cookies.get("jwt")}`,
          },
          body: JSON.stringify({ data }),
        }
      );
      if (!response.ok) {
        return false;
      }
      const result = await response.json();
      if (result.message !== "Success") {
        return false;
      }
      pushEventsToDataLayer({
        event: "form_submitted",
        "data-form-request": formData.requestType || "",
      });
      return true;
    } catch (error) {
      console.error("Error sending email:", error);
      return false;
    }
  }

  const [disabled, setDisabled] = useState(false);

  return (
    <>
      <header
        className=" top-0 bg-white z-50 border-b remove-border-no-bottom border-lightgray fixed w-full border-solid"
        style={{
          borderTopWidth: "0px",
          borderLeftWidth: "0px",
          borderRightWidth: "0px",
        }}
      >
        <div>
          <div className="flex items-center justify-between h-[60px] px-4 md:px-[40px]">
            <div>
              <div
                className="flex cursor-pointer  items-end gap-1 w-fit"
                onClick={() => {
                  navigate("/");
                }}
              >
                <LazyLoadImage src={Logo} width="150" />
                <span className="text-[10px] whitespace-nowrap h-[17px]">
                  | Powered by Fortop
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="flex items-center justify-center p-20">
        {sendForm ? (
          <div className="flex items-center justify-center flex-col">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Thanks for contacting us!
            </h2>
            <p className="text-gray-600 text-center">
              We will reply to you as soon as possible at the email address{" "}
              <strong>{formData.email}</strong>.
            </p>
            {message && (
              <p className="text-red-500 text-lg mt-1 text-center">{message}</p>
            )}
          </div>
        ) : (
          <div className="bg-white md:p-6 rounded-lg shadow-lg w-full">
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="flex gap-x-2 md:gap-4    w-full items-center md:flex-row flex-col">
                <div className="w-full">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    readOnly
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-[#FFD78A]
                    cursor-not-allowed
                    "
                  />
                  <p className="text-red-500 text-sm mt-1 h-5">
                    {errors.email}
                  </p>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="fullName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    readOnly
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-[#FFD78A]
                    cursor-not-allowed
                    "
                  />
                  <p className="text-red-500 text-sm mt-1 h-5">
                    {errors.fullName}
                  </p>
                </div>
              </div>
              <div
                className="flex gap-2 md:gap-4  w-full items-center md:flex-row flex-col"
                style={{
                  marginTop: "0px",
                }}
              >
                <div className="w-full">
                  <label
                    htmlFor="mobile"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mobile (Optional)
                  </label>
                  <input
                    type="tel"
                    id="mobile"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFD78A]"
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company (Optional)
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFD78A]"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="requestType"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type of Request
                </label>
                <select
                  id="requestType"
                  name="requestType"
                  value={formData.requestType}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFD78A]"
                >
                  <option value="">Select a type</option>
                  <option value="Technical Support">Technical Support</option>
                  <option value="Customer Care">Customer Care</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Others">Others</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description <span className="text-red-500">*</span>
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows="4"
                  value={formData.description}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFD78A]"
                ></textarea>
                {errors.description && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.description}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="recaptcha"
                  className="block text-sm font-medium text-gray-700"
                >
                  Solve: {captcha}
                </label>
                <input
                  type="text"
                  id="recaptcha"
                  name="recaptcha"
                  value={formData.recaptcha}
                  onChange={handleChange}
                  className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFD78A]"
                />
                {errors.recaptcha && (
                  <p className="text-red-500 text-sm mt-1 h-5">
                    {errors.recaptcha}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="privacyPolicy"
                  className="block text-sm font-medium text-gray-700"
                >
                  <input
                    type="checkbox"
                    id="privacyPolicy"
                    name="privacyPolicy"
                    checked={formData.privacyPolicy}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  I agree to the{" "}
                  <a
                    href="/privacy-policy"
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacy policy
                  </a>{" "}
                  and the{" "}
                  <a
                    href="/terms-and-conditions"
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    terms and conditions
                  </a>
                  <span className="text-red-500">*</span>
                </label>
                {errors.privacyPolicy && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.privacyPolicy}
                  </p>
                )}
              </div>

              <div>
                <button
                  type="submit"
                  disabled={disabled}
                  className="w-full bg-gradient-to-r from-[#FFD78A]  to-[#F4762D] text-white py-2 px-4 rounded-md shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFD78A]"
                >
                  Submit
                </button>
              </div>

              {message && (
                <p className="text-red-500 text-sm mt-1 text-center">
                  {message}
                </p>
              )}
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default Form;
